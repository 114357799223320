import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Card,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";

import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";
import Select from "react-select";

import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-number-input";

import DTable from "../components/Table";

//const Orders = () => {
const Orders = ({ props, history }) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedCorporate, setSelectedCorporate] = useState("all");
  const [corporates, setCorporates] = useState([]);

  const [orderStatus, setOrderStatus] = useState("All");
  const [orderStatus2, setOrderStatus2] = useState("Completed");

  const [paymentStatus, setPaymentStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [createOrderModal, setCreateOrderModal] = useState(false);

  const [products, setProducts] = useState([]);
  const [varients, setVarients] = useState([]);

  const [users, setUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedVarient, setSelectedVarient] = useState("");
  const [activeAccordion, setActiveAccordion] = useState("");
  const contentBilling = useRef();
  const contentShipping = useRef();

  const [cardImage, setCardImage] = useState("/api/media/profile-bg-2.png");

  const [errors, setErrors] = useState({});

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [newOrderStatus, setNewOrderStatus] = useState("Processing");
  //const [newOrderPayment, setNewOrderPayment] = useState("Processing");

  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [addCity, setAddCity] = useState("select");
  const [addState, setAddState] = useState("select");
  const [addCountry, setAddCountry] = useState("India");
  const [addLandmark, setAddLandmark] = useState("");
  const [addZipcode, setAddZipcode] = useState("");
  const [addType, setAddType] = useState(0);
  const [giftCard, setGiftCard] = useState(false);

  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [applyCode, setApplyCode] = useState(false);
  const [discountType, setDiscountType] = useState("percentage");

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [ordersList, setOrdersList] = useState(orders);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [checkAllOrder, setCheckAllOrder] = useState(false);

  const loadCorporates = async () => {
    try {
      const { data } = await axiosInstance.post("admin/corporates", {
        //search:search
      });

      if (data.status) {
        setCorporates(data.corporates);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onBulkOrderStatusChange = async (orderId, status) => {
    console.log("onBulkOrderStatusChange");

    const { data } = await axiosInstance.post("admin/bulk-order-status", {
      orders: selectedOrders,
      status: orderStatus2,
    });

    if (data.status) {
      swal("Bulk Order", data.message, "success");

      var dummy = [...ordersList];

      selectedOrders.map((order, i) => {
        const orderIndex = dummy.findIndex((x) => x.id === order);

        if (orderIndex != -1) {
          dummy[orderIndex].status = orderStatus;
          console.log("order " + order + " ==> " + orderStatus);
        } else {
          console.log("order " + order + " ==> Not found");
        }
      });

      setOrdersList(dummy);
      setSelectedOrders([]);
      setCheckAllOrder(false);
      loadOrders();
    } else {
      swal("Bulk Order", data.message, "error");
    }
  };

  const changeOrderStatus = async (orderId, status) => {
    const { data } = await axiosInstance.post("admin/order-status", {
      order_id: orderId,
      status: status,
    });

    if (data.status) {
      swal("Order", data.message, "success");

      var dummy = [...ordersList];

      const orderIndex = dummy.findIndex((x) => x.id === orderId);

      dummy[orderIndex].status = status;
      console.log("order index - " + orderIndex);

      setOrdersList(dummy);
      onClick(activePage);
    } else {
      swal("Order", data.message, "error");
    }
  };

  const subTotal = (items) => {
    var total = 0;
    items.map((item, i) => {
      total = total + item.quantity * item.total;
    });

    return total;
  };

  function onAllOrderCheck(checked) {
    console.log("onAllOrderCheck - " + checked);

    if (checked) {
      let dummy = [];
      for (let key in orders) {
        dummy.push(orders[key].id);
      }

      setSelectedOrders(dummy);
      console.log("onAllOrderCheck orders - " + JSON.stringify(dummy));
    } else {
      setSelectedOrders([]);
      console.log("onAllOrderCheck orders removed");
    }

    setCheckAllOrder(checked);
  }

  function onOrderCheck(data, checked) {
    console.log("onOrderCheck - " + checked);

    const dummy = [...selectedOrders];

    if (checked) {
      dummy.push(data);
      setSelectedOrders(dummy);

      if (dummy.length != orders.length) {
        setCheckAllOrder(false);
      } else {
        setCheckAllOrder(true);
      }

      console.log("onOrderCheck item " + data + " added");
    } else {
      const orderIndex = dummy.findIndex((x) => x === data);

      if (orderIndex != -1) {
        dummy.splice(orderIndex, 1);
        console.log("onOrderCheck item " + orderIndex + " removed");
      } else {
        //dummy.push(memberId);
        console.log("onOrderCheck item " + orderIndex + " not found");
      }

      setSelectedOrders(dummy);
      setCheckAllOrder(false);
    }

    console.log("onOrderCheck list - " + JSON.stringify(dummy));
  }

  useEffect(() => {
    const cc = Country.getAllCountries().find(
      (x) => x.name == addCountry
    ).isoCode;

    setStates(State.getStatesOfCountry(cc));
    setCities(City.getCitiesOfCountry(cc));

    setAddCity("select");
    setAddState("select");
  }, [addCountry]);

  useEffect(() => {
    const countries = Country.getAllCountries();

    const cc = countries.find((x) => x.name == addCountry).isoCode ?? "";

    const state = states.find((x) => x.name == addState);

    //setStates(State.getStatesOfCountry(cc))
    setCities(City.getCitiesOfState(cc, state ? state.isoCode : ""));
  }, [addState]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(25);
  const pageSizes = [10, 25, 50, 100, 1000, 10000];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadOrders();
  }, [page, pageSize, orderStatus, paymentStatus, selectedCorporate]);

  // Data Table End
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadOrders();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    const cc = Country.getAllCountries().find(
      (x) => x.name == addCountry
    ).isoCode;

    setStates(State.getStatesOfCountry(cc));
    setCities(City.getCitiesOfCountry(cc));

    loadOrders();
    loadProducts();
    loadUsers();
    loadCorporates();
  }, [dispatch]);

  useEffect(() => {
    if (orders) {
      //setLoading(false);
    }
  }, [orders]);

  const loadUsers = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      search: "",
    });

    if (data.status) {
      setUsers(data.users);
    }
  };

  const loadProducts = async () => {
    const { data } = await axiosInstance.get("products");

    if (data) {
      setProducts(data);
    }
  };

  const onChangeUser = async (id) => {
    setSelectedUser(id);

    const index = users.findIndex((x) => x.id === id);

    if (index != -1) {
      setPhone(users[index].phone);
      setEmail(users[index].email);
      setName(users[index].name);
    } else {
      setPhone("");
      setEmail("");
      setName("");
    }
  };

  const onChangeProduct = async (id) => {
    setSelectedProduct(id);
    const index = products.findIndex((x) => x.id === id);

    if (index != -1) {
      var dummy = products[index].variations
        .filter((v) => v.enable)
        .map((v) => v);

      console.log("product index - " + index);
      console.log("varient image - " + dummy[0].dimensions.template.frontImage);
      console.log(dummy[0]._id);
      setVarients(dummy);
      setSelectedVarient(dummy[0]._id);
    } else {
      setVarients([]);
    }
  };

  /*
  //Remove Variants From UI 23-08-2024
  const onChangeVarient = async (id) => {
    setSelectedVarient(id);

    const index = varients.findIndex((x) => x._id === id);

    if (index != -1) {
      var image = varients[index].dimensions.template.frontImage;
      console.log("varient image - " + image);
      setCardImage(image);
    } else {
      setCardImage("/api/media/profile-bg-2.png");
    }
  };
  */

  const loadOrders = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("admin/orders", {
      status: orderStatus,
      payment_status: paymentStatus,
      search: search,
      page: page,
      limit: pageSize,
      corporate_id:
        selectedCorporate == "all" || selectedCorporate == "corporate"
          ? selectedCorporate
          : selectedCorporate.id,
    });

    if (data.status) {
      setOrders(data.orders);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
    }
    setLoading(false);
  };

  const changeOrderStatusFilter = async (filter) => {
    setOrderStatus(filter);
    //loadOrders(filter, paymentStatus);
  };

  const changePaymentStatusFilter = async (filter) => {
    setPaymentStatus(filter);
    //loadOrders(orderStatus, filter);
  };

  const changeOrderStatusFilter2 = async (filter) => {
    setOrderStatus2(filter);
  };

  const initXlsData = async () => {
    var dummy = [];
    orders.map((data, i) => {
      var address = "";

      var shippingAddress = data.shipping.address;

      if (shippingAddress) {
        address =
          (shippingAddress.addressLine1 ?? "") +
          ", " +
          (shippingAddress.addressLine2 ?? "") +
          ", " +
          (shippingAddress.city ?? "") +
          ", " +
          (shippingAddress.state ?? "") +
          ", " +
          (shippingAddress.country ?? "") +
          " - " +
          (shippingAddress.zip ?? "");
      }

      var product = "";
      data.items.map((item, i) => {
        var productImage = "";
        var productFrontImage = "";
        var productBackImage = "";

        const variationIndex = item.productId.variations.findIndex(
          (x) => x._id === item.variationId
        );

        if (variationIndex >= 0) {
          productFrontImage =
            item.productId.variations[variationIndex].dimensions.template
              .frontImage;
          productBackImage =
            item.productId.variations[variationIndex].dimensions.template
              .backImage;
          productImage =
            "\nFront Image -" +
            productFrontImage +
            "\nBack Image -" +
            productBackImage;
        }

        product =
          product +
          "" +
          item.productId.name +
          " " +
          item.quantity +
          "X" +
          " * " +
          data.currency +
          " " +
          item.price +
          " = " +
          data.currency +
          " " +
          item.total +
          productImage +
          "\n";
      });

      dummy.push({
        sr_no: i + 1,
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        gst_number: data.gst,
        is_gift: data.isGift,
        shipping_address: address,

        product: product,

        type: data.type ?? "new",
        status: data.status,
        payment_status: data.paymentStatus,

        discount: data.discountTotal,
        total: data.orderTotal,
        coupon: data.coupon,

        ship_rocket_order_id:
          data.shipRocket != null ? data.shipRocket.orderId : "",
        ship_rocket_shipment_id:
          data.shipRocket != null ? data.shipRocket.shipmentId : "",

        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSelectedProduct("");
    setSelectedUser("");
    setSelectedVarient("");
    setGst("");
    setNewOrderStatus("Processing");
    setAddLine1("");
    setAddLine2("");
    setAddCity("");
    setAddLandmark("");
    setAddState("");
    setAddZipcode("");
    setCoupon("");
    setApplyCode(false);
  };

  const onCreateOrder = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedUser === "") {
      error = true;
      errorObj.user = "User is Required";
    }

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    /*if (selectedVarient === "") {
      error = true;
      errorObj.varient = "Product varient is Required";
    }

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone Number is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }

    if (addLine1 === "") {
      error = true;
      errorObj.addLine1 = "Address Line 1 is Required";
    }

    if (addCity === "") {
      error = true;
      errorObj.addCity = "Address City is Required";
    }

    if (addState === "") {
      error = true;
      errorObj.addState = "Address State is Required";
    }

    if (addZipcode === "") {
      error = true;
      errorObj.addZipcode = "Address Zipcode is Required";
    }*/

    // const product = getProduct();
    const variation = getVariation();

    setErrors(errorObj);
    if (!error) {
      var formData = {
        user_id: selectedUser,
        name: name,
        email: email,
        phone: phone,
        gst: gst,
        coupon: coupon,
        add_line_1: addLine1,
        add_line_2: addLine2,
        city: addCity,
        state: addState,
        landmark: addLandmark,
        zipcode: addZipcode,
        country: addCountry,
        add_type: addType,
        is_gift: false,
        payment_method: "manual",

        items: JSON.stringify([
          {
            productId: selectedProduct,
            variationId: selectedVarient,
            quantity: 1,
            price: variation.price,
            sku: variation.sku,
            //cardDetails:item.cardDetails
            name: name,
            designation: "",
            company: "",
            phone: phone,
            discount: 0,
            discountTax: 0,
            tax: 0,
            total: Number(variation.price * 1),
          },
        ]),
      };

      const { data } = await axiosInstance.post(
        "orders/manual-order",
        formData
      );

      if (data.status === false) {
        swal("Create Order", data.message, "error");
      } else {
        setCreateOrderModal(false);
        swal("Create Order", data.message, "success");

        setTimeout(() => {
          loadOrders();
        }, 500);

        clearForm();
      }
    }
  };

  function getProduct() {
    const index = products.findIndex((x) => x.id === selectedProduct);

    if (index != -1) {
      return products[index];
    } else {
      return null;
    }
  }

  function getVariation() {
    const index = varients.findIndex((x) => x._id === selectedVarient);

    if (index != -1) {
      return varients[index];
    } else {
      return null;
    }
  }

  const getCartSubTotal = () => {
    const product = getProduct();

    if (product) {
      return product.salePrice.toFixed(2);
    } else {
      return 0;
    }
  };

  const getCartDiscountTotal = () => {
    var subTotal = getCartSubTotal();

    if (applyCode) {
      if (discountType == "percentage") {
        return ((subTotal * discount) / 100).toFixed(2);
      } else {
        return discount.toFixed(2);
      }
    } else {
      return 0.0;
    }
  };

  const getCartTotal = () => {
    return getCartSubTotal() - getCartDiscountTotal();
  };

  const applyCouponCode = async (e) => {
    e.preventDefault();

    // if(coupon == "JTO100OFF"){
    //   setApplyCode(true);
    // }

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      //axios.post("/api/auth/signIn",fd,config);
      const { data } = await axiosInstance.post(
        "orders/check-coupon",
        { code: coupon },
        config
      );

      if (data.status == true) {
        setCoupon(data.coupon.code);
        setDiscount(data.coupon.discount);
        setDiscountType(data.coupon.type);
        setApplyCode(true);
      } else {
        setCoupon("");
        setDiscount(0);
        setDiscountType("percentage");
        setApplyCode(false);
      }
    } catch (error) {
      //toast.error("something is wrong");
    }
  };

  const columns = [
    {
      // Header: (_)=> {
      //   return (<i>Check All</i>)
      // },
      Header: "",
      accessor: "updatedAt",
      sort: false,

      Cell: (row) => {
        const d = row.row.original;

        return (
          <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"checkbox-" + row.row.id}
              checked={selectedOrders.includes(d.id)}
              onChange={(e) => onOrderCheck(d.id, e.target.checked)}
              required=""
            />
            <label
              className="custom-control-label"
              htmlFor={"checkbox-" + row.row.id}
            ></label>
          </div>
        );
      },
    },
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Link to={"/order/" + d.id}>
            <span className="text-black">{d.name}</span>
            <br />
            <small>#{d.id}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.phone}</small>
          </Link>
        );
      },
    },

    {
      Header: "Card",
      accessor: "card",
      Cell: ({ row }) => {
        const d = row.original;
        return d.items.map((item, i) => (
          <span className="text-primary">{item.productId.name}</span>
        ));
      },
    },

    {
      Header: "Date",
      accessor: "cratedAt",
      Cell: ({ row }) => {
        const d = row.original;
        return format(new Date(d.createdAt), "dd/MM/yyyy H:mma");
      },
    },

    {
      Header: "Is Corporate",
      accessor: "isCorporate",
      Cell: ({ row }) => {
        const d = row.original;
        return d.isCorporate === true ? (
          <Badge variant="success text-white">YES</Badge>
        ) : (
          <Badge variant="warning text-white">NO</Badge>
        );
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const d = row.original;
        return d.status === "Completed" ? (
          <Badge variant="success text-white">
            Completed
            <span className="ml-1 fa fa-check"></span>
          </Badge>
        ) : d.status === "On Hold" ? (
          <Badge variant="secondary">
            On Hold
            <span className="ml-1 fa fa-ban"></span>
          </Badge>
        ) : d.status === "Pending" ? (
          <Badge variant="warning">Pending</Badge>
        ) : d.status === "Processing" ? (
          <Badge variant="primary text-white">Processing</Badge>
        ) : d.status === "Trial" ? (
          <Badge variant="warning text-white">Trial</Badge>
        ) : (
          <Badge>{d.status}</Badge>
        );
      },
    },

    {
      Header: "Payment",
      accessor: "payment",
      Cell: ({ row }) => {
        const d = row.original;
        return d.paymentStatus === "paid" ? (
          <Badge variant="success light">Paid</Badge>
        ) : (
          <Badge variant="danger light">Unpaid</Badge>
        );
      },
    },

    {
      Header: "Subtotal",
      accessor: "subtotal",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <>
            {d.currency} {subTotal(d.items)}
          </>
        );
      },
    },

    {
      Header: "Discount",
      accessor: "discount",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="left">
            <strong>
              {d.discountTotal != 0
                ? (d.discountTotal * 100) / d.discountTotal + " - " + d.coupon
                : 0}
            </strong>
            <br />
            {d.currency} {d.discountTotal}
          </div>
        );
      },
    },

    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => {
        const d = row.original;
        return <strong>{d.currency + " " + d.orderTotal}</strong>;
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Processing")}
              >
                Processing
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Printed")}
              >
                Printed
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Packed")}
              >
                Packed
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Dispatched")}
              >
                Dispatched
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Completed")}
              >
                Completed
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "On Hold")}
              >
                On Hold
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={(e) => changeOrderStatus(d.id, "Pending")}
              >
                Pending
              </Dropdown.Item>
              {/*  <div className="dropdown-divider"></div>
                                    <Dropdown.Item
                                       href="#"
                                       className="text-danger"
                                    >
                                       Delete
                                    </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const selectedCorporateName = () => {
    if (selectedCorporate == "all") {
      return "All Orders(Users + Corporate)";
    } else if (selectedCorporate == "corporate") {
      return "All Corporate Orders";
    } else {
      return (
        <>
          {selectedCorporate?.name}
          <small>({selectedCorporate.email}) </small>
        </>
      );
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={createOrderModal}
        size={"lg"}
        onHide={setCreateOrderModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onCreateOrder}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Create Order</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setCreateOrderModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-black font-w500 required-field">
                            User
                          </label>

                          <Select
                            defaultValue={selectedUser}
                            onChange={(option) => onChangeUser(option.value)}
                            options={users.map((user, i) => {
                              return {
                                value: user.id,
                                label: user.name + "(" + user.email + ")",
                              };
                            })}
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                          />

                          {errors.user && (
                            <div className="text-danger fs-12">
                              {errors.user}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="text-black font-w500 required-field">
                            Product
                          </label>

                          <Select
                            defaultValue={selectedProduct}
                            onChange={(option) => onChangeProduct(option.value)}
                            options={products.map((product, i) => {
                              return {
                                value: product.id,
                                label: product.name,
                              };
                            })}
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                          />
                          {errors.product && (
                            <div className="text-danger fs-12">
                              {errors.product}
                            </div>
                          )}
                        </div>

                        {/* <div className="form-group ">
                          <label className="text-black font-w500 required-field">
                            Varient
                          </label>

                          <Select
                            defaultValue={selectedVarient}
                            onChange={(option) => onChangeVarient(option.value)}
                            options={varients.map((variation, i) => {
                              return {
                                value: variation._id,
                                label: variation.dimensions.template.name,
                              };
                            })}
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                          />

                          {errors.varient && (
                            <div className="text-danger fs-12">
                              {errors.varient}
                            </div>
                          )}
                        </div> */}
                      </div>

                      <div className="col-md-6">
                        <label className="text-black font-w500">Preview</label>

                        <Card className="card b-0">
                          <Card.Img
                            className="img-thumbnail"
                            variant="top"
                            src={cardImage}
                          />
                        </Card>
                      </div>
                    </div>

                    <div className="rc-accordion-card mt-2">
                      <div className="rc-accordion-header">
                        <div className={`rc-accordion-toggle p-3 ${activeAccordion === 'billingDetails' ? 'active' : ''}`} onClick={() => setActiveAccordion(`${activeAccordion === 'billingDetails' ? '' : 'billingDetails'}`)}>
                          <h5 className="rc-accordion-title">Billing Detail</h5>
                          <i className="fa fa-chevron-down rc-accordion-icon"></i>
                        </div>
                      </div>
                      <div ref={contentBilling} className={`rc-collapse ${activeAccordion === 'billingDetails' ? 'show' : ''}`} style={
                        activeAccordion === 'billingDetails'
                          ? { height: contentBilling.current.scrollHeight }
                          : { height: "0px" }
                      }>
                        <div className="rc-accordion-body">
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Name
                                </label>

                                <input
                                  type="text"
                                  value={name}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder="Name"
                                />
                                {errors.name && (
                                  <div className="text-danger fs-12">
                                    {errors.name}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Phone Number
                                </label>

                                {/* <input
                            type="phone"
                            value={phone}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone Number"
                          /> */}

                                <PhoneInput
                                  defaultCountry="IN"
                                  placeholder="Enter phone number"
                                  className=""
                                  value={phone}
                                  onChange={setPhone}
                                />

                                {errors.phone && (
                                  <div className="text-danger fs-12">
                                    {errors.phone}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="text-black font-w500">
                                  Email Address
                                </label>

                                <input
                                  type="email"
                                  value={email}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder="Email Address"
                                />
                                {errors.email && (
                                  <div className="text-danger fs-12">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="text-black font-w500">
                                  GST Number
                                </label>

                                <input
                                  type="text"
                                  value={gst}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setGst(e.target.value)}
                                  placeholder="GST Number"
                                />
                                {errors.gst && (
                                  <div className="text-danger fs-12">
                                    {errors.gst}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="text-black font-w500">
                                  Order Status
                                </label>

                                <select
                                  value={newOrderStatus}
                                  className="form-control"
                                  onChange={(e) => setNewOrderStatus(e.target.value)}
                                >
                                  <option value="Processing">Processing</option>
                                  <option value="Printed">Printed</option>
                                  <option value="Packed">Packed</option>
                                  <option value="Dispatched">Dispatched</option>
                                  <option value="Completed">Completed</option>
                                  <option value="On Hold">On Hold</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Trial">Trial</option>
                                </select>
                                {errors.orderStatus && (
                                  <div className="text-danger fs-12">
                                    {errors.orderStatus}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rc-accordion-card mt-2">
                      <div className="rc-accordion-header">
                        <div className={`rc-accordion-toggle p-3 ${activeAccordion === 'shippingAddress' ? 'active' : ''}`} onClick={() => setActiveAccordion(`${activeAccordion === 'shippingAddress' ? '' : 'shippingAddress'}`)}>
                          <h5 className="rc-accordion-title">Shipping Address</h5>
                          <i className="fa fa-chevron-down rc-accordion-icon"></i>
                        </div>
                      </div>
                      <div ref={contentShipping} className={`rc-collapse ${activeAccordion === 'shippingAddress' ? 'show' : ''}`} style={
                        activeAccordion === 'shippingAddress'
                          ? { height: contentShipping.current.scrollHeight }
                          : { height: "0px" }
                      }>
                        <div className="rc-accordion-body">
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Address Type
                                </label>

                                <select
                                  type="text"
                                  value={addType}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setAddType(e.target.value)}
                                >
                                  <option value="Office">Office</option>
                                  <option value="Home">Home</option>
                                </select>

                                {errors.addType && (
                                  <div className="text-danger fs-12">
                                    {errors.addType}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Address Line 1
                                </label>

                                <input
                                  type="text"
                                  value={addLine1}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setAddLine1(e.target.value)}
                                  placeholder="Address Line 1"
                                />
                                {errors.addLine1 && (
                                  <div className="text-danger fs-12">
                                    {errors.addLine1}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Address Line 2
                                </label>

                                <input
                                  type="text"
                                  value={addLine2}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setAddLine2(e.target.value)}
                                  placeholder="Address Line 2"
                                />
                                {errors.addLine2 && (
                                  <div className="text-danger fs-12">
                                    {errors.addLine2}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  City
                                </label>

                                {/* <input
                            type="text"
                            value={addCity}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => setAddCity(e.target.value)}
                            placeholder="City"
                          /> */}

                                <Form.Control
                                  as="select"
                                  className="main"
                                  value={addCity}
                                  onChange={(e) => {
                                    console.log("e.target.value", e.target.value);
                                    setAddCity(e.target.value);
                                  }}
                                >
                                  <option value={"select"}>Select</option>
                                  {cities.map((x, i) => (
                                    <option key={"s-" + i} value={x.name}>
                                      {x.name}
                                    </option>
                                  ))}
                                </Form.Control>

                                {errors.addCity && (
                                  <div className="text-danger fs-12">
                                    {errors.addCity}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  State
                                </label>

                                <Form.Control
                                  as="select"
                                  className="main"
                                  value={addState}
                                  onChange={(e) => {
                                    console.log("e.target.value", e.target.value);

                                    setAddState(e.target.value);
                                  }}
                                >
                                  <option value={"select"}>Select</option>
                                  {states.map((x, i) => (
                                    <option key={"s-" + i} value={x.name}>
                                      {x.name}
                                    </option>
                                  ))}
                                </Form.Control>

                                {errors.addState && (
                                  <div className="text-danger fs-12">
                                    {errors.addState}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Country
                                </label>

                                <Form.Control
                                  as="select"
                                  className="main"
                                  value={addCountry}
                                  onChange={(e) => {
                                    console.log("e.target.value", e.target.value);
                                    setAddCountry(e.target.value);
                                  }}
                                >
                                  {Country.getAllCountries().map((x, i) => (
                                    <option key={"c-" + i} value={x.name}>
                                      {x.name}
                                    </option>
                                  ))}
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Landmark
                                </label>

                                <input
                                  type="text"
                                  value={addLandmark}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setAddLandmark(e.target.value)}
                                  placeholder="Landmark"
                                />
                                {errors.addLandmark && (
                                  <div className="text-danger fs-12">
                                    {errors.addLandmark}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-black font-w500">
                                  Zipcode
                                </label>

                                <input
                                  type="text"
                                  value={addZipcode}
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={(e) => setAddZipcode(e.target.value)}
                                  placeholder="Zipcode"
                                />
                                {errors.addZipcode && (
                                  <div className="text-danger fs-12">
                                    {errors.addZipcode}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-2">

                      <div className="col-md-6">
                        <InputGroup className="">
                          <FormControl
                            placeholder="Coupon Code"
                            aria-label="Coupon Code"
                            aria-describedby="basic-apply"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                          />
                          <Button
                            variant="primary"
                            className="btn btn-sm"
                            onClick={applyCouponCode}
                            id="button-apply"
                            style={{ borderRadius: "0px 5px 5px 0px" }}
                          >
                            Apply
                          </Button>
                        </InputGroup>
                      </div>

                      <div className="col-md-6">
                        <div className="row lower">
                          <div className="col text-left">Subtotal</div>
                          <div className="col text-right">₹{getCartSubTotal()}</div>
                        </div>
                        <div className="row lower">
                          <div className="col text-left">Shipping</div>
                          <div className="col text-right text-success">
                            <b>FREE</b>
                          </div>
                        </div>

                        <div className="row lower">
                          <div className="col text-left">GST @ 18%</div>
                          <div className="col text-right">Included</div>
                        </div>

                        {applyCode ? (
                          <div className="row lower">
                            <div className="col text-left">Discount</div>
                            <div className="col text-right">-₹{getCartDiscountTotal()}</div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="row lower mb-2 order-total">
                          <div className="col text-left">
                            <b>Total to pay</b>
                          </div>
                          <div className="col text-right">
                            <bdi>
                              <strong className="amount">
                                ₹{getCartTotal()}
                              </strong>
                            </bdi>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="line" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setCreateOrderModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Orders</h4>

              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadOrders();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <Dropdown className="mr-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {selectedCorporateName()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => setSelectedCorporate("all")}>
                    All Orders (User + Corporate)
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={(e) => setSelectedCorporate("corporate")}
                  >
                    All Corporate Orders
                  </Dropdown.Item>

                  {corporates.map((corporate) => (
                    <Dropdown.Item
                      onClick={(e) => setSelectedCorporate(corporate)}
                    >
                      {corporate?.name}
                      <small>({corporate.email}) </small>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="mr-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {paymentStatus} Payment
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => changePaymentStatusFilter("All")}
                  >
                    All Payment
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changePaymentStatusFilter("paid")}
                  >
                    Paid
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changePaymentStatusFilter("unpaid")}
                  >
                    Unpaid
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="mr-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {orderStatus} Orders
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("All")}
                  >
                    All Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Processing")}
                  >
                    Processing Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Printed")}
                  >
                    Printed Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Packed")}
                  >
                    Packed Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Dispatched")}
                  >
                    Dispatched Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Completed")}
                  >
                    Completed Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("On Hold")}
                  >
                    On Hold Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Pending")}
                  >
                    Pending Orders
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => changeOrderStatusFilter("Trial")}
                  >
                    Trial Orders
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button
                className="btn btn-primary mr-2"
                onClick={(e) => setCreateOrderModal(true)}
              >
                Create Order
              </Button>

              <CSVLink
                data={xlsData}
                filename={"orders.csv"}
                className="btn btn-primary"
                target="_blank"
                asyncOnClick={true}
                onClick={async (event, done) => {
                  console.log("You click the link");
                  await initXlsData();
                  done(); // 👍🏻 You are stopping the handling of component
                }}
              >
                Export
              </CSVLink>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-1 text-center p-3">
                  <div className="custom-control custom-checkbox checkbox-success check-lg">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkAll"
                      required=""
                      checked={checkAllOrder}
                      onChange={(e) => onAllOrderCheck(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkAll"
                    ></label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-lg-inline-flex d-none">
                    <Dropdown className="mr-2">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {orderStatus2} Orders
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) =>
                            changeOrderStatusFilter2("Processing")
                          }
                        >
                          Processing Orders
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("Printed")}
                        >
                          Printed Orders
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("Packed")}
                        >
                          Packed Orders
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) =>
                            changeOrderStatusFilter2("Dispatched")
                          }
                        >
                          Dispatched Orders
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("Completed")}
                        >
                          Completed Orders
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("On Hold")}
                        >
                          On Hold Orders
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("Pending")}
                        >
                          Pending Orders
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => changeOrderStatusFilter2("Delete")}
                        >
                          Delete Orders
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button
                      variant=""
                      className="btn btn-sm btn-primary"
                      onClick={() => onBulkOrderStatusChange(false)}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>

              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : orders.length == 0 ? (
                <p>No Orders</p>
              ) : (
                <DTable
                  columns={columns}
                  data={orders}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />

                // <OrderTable
                //   orders={orders}
                //   loadOrders={() => loadOrders(orderStatus, paymentStatus)}
                // ></OrderTable>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Orders;
