import React, { useState, useEffect, Fragment } from "react";
import { Table, Pagination, Badge, Dropdown, Spinner, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import axiosInstance from '../../services/AxiosInstance';
import themeList from "../../utils/themes";

//const Orders = () => {
const Corporates = ({ props, history }) => {

   const dispatch = useDispatch();
   const [corporates, setCorporates] = useState([]);
   const [xlsData, setXlsData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [search, setSearch] = useState("");
   const [searchTimeout, setSearchTimeout] = useState(null);
   const [corporateId, setCorporateId] = useState("");
   const [updateTheme, setUpdateTheme] = useState(false);
   const [addThemeModal, setAddThemeModal] = useState(false);
   const [selectedTheme, setSelectedTheme] = useState([]);
   const [errors, setErrors] = useState("");

   useEffect(() => {
      if (searchTimeout) {
         clearTimeout(searchTimeout);
      }

      setSearchTimeout(
         setTimeout(() => {
            loadCorporates();
         }, 1000),
      );

      return () => clearTimeout(searchTimeout);
   }, [search]);

   const sort = 50;
   let pagination = Array(Math.ceil(corporates.length / sort))
      .fill()
      .map((_, i) => i + 1);

   const [activePage, setActivePage] = useState(0);
   const [tableData, setTableData] = useState([]);

   useEffect(() => {
      loadCorporates();
   }, [dispatch]);

   useEffect(() => {

      if (corporates) {
         setLoading(false);
         setTableData(corporates.slice(
            activePage * sort,
            (activePage + 1) * sort
         ));
      }

   }, [corporates]);

   const loadCorporates = async () => {

      const { data } = await axiosInstance.post(
         "admin/corporates", {
         search: search
      }
      );

      if (data.status) {
         setCorporates(data.corporates);
      }
   }

   const initXlsData = async () => {

      var dummy = [];
      corporates.map((data, i) => {

         var address = "";
         if (data.address) {
            if (data.address.addressLine1) {

               address = (data.address.addressLine1 ?? "") + ", " +
                  (data.address.addressLine2 ?? "") + ", " +
                  (data.address.city ?? "") + ", " +
                  (data.address.state ?? "") + ", " +
                  (data.address.country ?? "") + " - " +
                  (data.address.zip ?? "");

            } else {
               address = "Not Available";
            }
         }

         dummy.push({
            sr_no: i + 1,
            id: data.id,
            name: data.name,
            email: data.email,
            industry_type: data.industryType,
            gst_number: data.gstNumber,
            manager_name: data.managerName,
            manager_phone_number: (data.managerPhoneNumber ?? "").toString(),
            address: address,
            cards: data.cards,
            joined: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
            status: data.status === "active" ? "Activated" : "Deactivated"

         });
      });
      setXlsData(dummy);
   }

   const changeCorporateStatus = async (item) => {
      const status = item.status === "active" ? "deactivate" : "active";
      if (status === "active") {
         setCorporateId(item.id);
         setSelectedTheme(item.theme);
         setAddThemeModal(true);
      }
      else {
         updateStatus(item.id, status)
      }
   }

   const updateCorporateTheme = (item) => {
      setCorporateId(item.id);
      setSelectedTheme(item.theme);
      setAddThemeModal(true);
      setUpdateTheme(true);
   }

   const updateStatus = async (corporateId, status) => {
      const { data } = await axiosInstance.post(
         "admin/user-status",
         {
            user_id: corporateId,
            status: status
         }
      );
      if (data.status) {
         swal('Corporate', data.message, "success");
         var dummy = [...corporates];
         const corporateIndex = dummy.findIndex(
            (x) => x.id === corporateId
         );
         dummy[corporateIndex].status = status;
         setCorporates(dummy);
         onClick(activePage);
      } else {
         swal('Corporate', data.message, "error");
      }
   }

   const onChangeTheme = async (event) => {
      event.preventDefault();
      let error = false;
      var errors = "";

      if (selectedTheme.length === 0) {
         error = true;
         errors = "Please select at least one theme to proceed.";
      }
      setErrors(errors);
      if (!error) {
         const { data } = await axiosInstance.post(
            updateTheme ? "admin/corporate-user-theme-update" : "admin/corporate-user-active-with-theme",
            {
               user_id: corporateId,
               theme: selectedTheme
            }
         );
         if (data.status) {
            setCorporateId("");
            setAddThemeModal(false);
            swal('Corporate', data.message, "success");
            var dummy = [...corporates];
            const corporateIndex = dummy.findIndex(
               (x) => x.id === corporateId
            );
            dummy[corporateIndex].status = "active";
            dummy[corporateIndex].theme = selectedTheme;
            setCorporates(dummy);
            setSelectedTheme([]);
            setUpdateTheme(false);
            onClick(activePage);
         } else {
            swal('Corporate', data.message, "error");
         }
      }
   }

   //const [demo, setdemo] = useState();
   const onClick = (i) => {
      console.log("onClick - " + i);
      setActivePage(i);
      setTableData(corporates.slice(
         i * sort,
         (i + 1) * sort
      ));
   };

   return (
      <Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

         <div className="col-12">
            <div className="card">
               <div className="card-header">
                  <h4 className="card-title mr-auto">Corporates</h4>


                  <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                     <form
                        onSubmit={(e) => {
                           e.preventDefault();
                           if (searchTimeout) {
                              clearTimeout(searchTimeout);
                           }
                           loadCorporates();
                        }}
                     >
                        <input
                           type="text"
                           className="form-control"
                           placeholder="Search here"
                           onChange={(e) => {
                              setSearch(e.target.value);
                           }}
                        />
                     </form>
                     <div className="input-group-append">
                        <span className="input-group-text">
                           <svg
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                                 fill="#A4A4A4"
                              />
                           </svg>
                        </span>
                     </div>
                  </div>


                  <CSVLink
                     data={xlsData}
                     filename={"corporates.csv"}
                     className="btn btn-primary"
                     target="_blank"
                     asyncOnClick={true}
                     onClick={async (event, done) => {
                        console.log("You click the link");
                        await initXlsData();
                        done(); // 👍🏻 You are stopping the handling of component
                     }}

                  >
                     Export
                  </CSVLink>

               </div>
               <div className="card-body">

                  {loading || corporates.length == 0 ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div> : (

                     <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                           <table id="example" className="display w-100 dataTable">
                              <thead>
                                 <tr role="row">
                                    <th>Corporate Id</th>
                                    <th>Name</th>
                                    <th>Industry Type</th>
                                    <th>Available Card</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {tableData.map((d, i) => (
                                    <tr key={d.id}>
                                       <td><Link to={"/corporate/" + d.id}>#{d.id}</Link></td>
                                       <td>{d.name}<br /><small>{d.email}</small></td>
                                       <td>{d.industryType}</td>
                                       <td><strong>{d.cards}</strong></td>
                                       <td>{format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                                       <td>
                                          {d.status === "active" ? (
                                             <Badge variant="success light">
                                                Activated
                                                <span className="ml-1 fa fa-check"></span>
                                             </Badge>
                                          ) : (
                                             <Badge variant="danger light">
                                                Deactivated
                                                <span className="ml-1 fa fa-ban"></span>
                                             </Badge>
                                          )}
                                       </td>
                                       <td>
                                          <Dropdown>
                                             <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                             >
                                                <svg
                                                   width="24px"
                                                   height="24px"
                                                   viewBox="0 0 24 24"
                                                   version="1.1"
                                                >
                                                   <g
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                   >
                                                      <rect
                                                         x="0"
                                                         y="0"
                                                         width="24"
                                                         height="24"
                                                      ></rect>
                                                      <circle
                                                         fill="#000000"
                                                         cx="5"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="12"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="19"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                   </g>
                                                </svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={(e) => changeCorporateStatus(d)}>
                                                   {d.status === "active" ? "Deactive Now" : "Active Now"}
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e) => updateCorporateTheme(d)}>
                                                   Manage Themes
                                                </Dropdown.Item>
                                                

                                                {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
                                             </Dropdown.Menu>
                                          </Dropdown>

                                       </td>


                                    </tr>
                                 ))}
                              </tbody>
                              <tfoot>
                                 <tr role="row">

                                    <th>Corporate Id</th>
                                    <th>Name</th>
                                    <th>Industry Type</th>
                                    <th>Available Card</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>

                                 </tr>
                              </tfoot>
                           </table>
                           <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="dataTables_info">
                                 Showing {activePage * sort + 1} to
                                 {corporates.length <
                                    (activePage + 1) * sort
                                    ? corporates.length
                                    : (activePage + 1) * sort}
                                 &nbsp;of {corporates.length} entries
                              </div>
                              <div className="dataTables_paginate paging_simple_numbers">
                                 <Pagination
                                    className="pagination-primary pagination-circle"
                                    size="lg"
                                 >
                                    <li
                                       className="page-item page-indicator "
                                       onClick={() =>
                                          activePage > 1 &&
                                          onClick(activePage - 1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-left" />
                                       </Link>
                                    </li>
                                    {pagination.map((number, i) => (
                                       <Pagination.Item
                                          key={i}
                                          className={
                                             activePage === i ? "active" : ""
                                          }
                                          onClick={() => onClick(i)}
                                       >
                                          {number}
                                       </Pagination.Item>
                                    ))}
                                    <li
                                       className="page-item page-indicator"
                                       onClick={() =>
                                          activePage + 1 <
                                          pagination.length &&
                                          onClick(activePage + 1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-right" />
                                       </Link>
                                    </li>
                                 </Pagination>
                              </div>
                           </div>
                        </div>
                     </Table>

                  )}




               </div>
            </div>
         </div>

         <Modal
            className="modal fade"
            show={addThemeModal}
            onHide={setAddThemeModal}
         >
            <div className="" role="document">
               <div className="">
                  <form onSubmit={onChangeTheme}>
                     <div className="modal-header">
                        <h4 className="modal-title fs-20">Choose Themes</h4>
                        <button
                           type="button"
                           className="close"
                           onClick={() => setAddThemeModal(false)}
                        >
                           <span>×</span>
                        </button>
                     </div>
                     <div className="modal-body">
                        <i className="flaticon-cancel-12 close"></i>
                        <div className="add-contact-box">
                           <div className="add-contact-content">
                              <div className="form-group mb-3">
                                 <label className="text-black font-w500">Themes</label>
                                 <div className="row">
                                    {themeList.map((x, key) => (
                                       <div className="col-md-6" key={key}>
                                          <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                                             <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={"theme-" + x.name}
                                                checked={selectedTheme.includes(x.name)}
                                                onChange={(e) => {
                                                   if (e.target.checked) {
                                                      if (!selectedTheme.includes(x.name)) {
                                                         setSelectedTheme((list) => [
                                                            ...list,
                                                            x.name,
                                                         ]);
                                                      }
                                                   } else {
                                                      setSelectedTheme((list) =>
                                                         list.filter((t) => t != x.name)
                                                      );
                                                   }
                                                }}
                                             />
                                             <label
                                                className="custom-control-label pl-2"
                                                htmlFor={"theme-" + x.name}
                                             >
                                                {x.title}
                                             </label>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                                 {errors && (
                                    <div className="text-danger fs-12">
                                       {errors}
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={() => setAddThemeModal(false)}
                           className="btn btn-danger"
                        >
                           {" "}
                           <i className="flaticon-delete-1"></i> Discard
                        </button>
                        <button type="submit" className="btn btn-primary">
                           {updateTheme ? "Update" : "Active Now"}
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </Modal>

      </Fragment>
   );
};

export default Corporates;
