import React, { useState, useEffect, Fragment } from "react";
import { Table, InputGroup, FormControl, Badge, Dropdown, Spinner, Modal, Button } from "react-bootstrap";

import Select from "react-select";

import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import xlsx from "xlsx";

import PhoneInput from "react-phone-number-input";

import DTable from "../components/Table";

import themeList from "../../utils/themes";

//const Orders = () => {
const Users = ({ props, history }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [coupon, setCoupon] = useState("");

  const [users, setUsers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  let errorsObj = { name: "", email: "", phone: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [editMemberModal, setEditMemberModal] = useState(false);
  const [activeMember, setActiveMember] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const [addMemberModal, setAddMemberModal] = useState(false);

  const [points, setPoints] = useState(0);

  const [giveRewardPointModal, setGiveRewardPointModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  //bulk upload
  const [checkAllUser, setCheckAllUser] = useState(false);
  const [bulkUserModal, setBulkUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState([]);
  const [bulkUserList, setBulkUserList] = useState([]);

  const [sendEmail, setSendEmail] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState([
    "light",
    "dark",
    "simple",
    "custom",
  ]);
  const [oneLink, setOneLink] = useState(true);
  const [vLink, setVLink] = useState(true);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(25);
  const pageSizes = [10, 25, 50, 100, 1000, 10000];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadUsers();
  }, [page, pageSize]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    loadProducts();
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      setLoading(false);
    }
  }, [users]);

  const loadUsers = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setUsers(data.users);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
    }
  };

  const loadProducts = async () => {
    const { data } = await axiosInstance.get("products");

    if (data) {
      setProducts(data);
    }
  };

  const onChangeProduct = async (id) => {
    setSelectedProduct(id);
  }

  const applyCouponCode = async (e) => {
    e.preventDefault();

    // if(coupon == "JTO100OFF"){
    //   setApplyCode(true);
    // }

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      //axios.post("/api/auth/signIn",fd,config);
      const { data } = await axiosInstance.post(
        "orders/check-coupon",
        { code: coupon },
        config
      );

      if (data.status == true) {
        setCoupon(data.coupon.code);
      } else {
        setCoupon("");
      }
    } catch (error) {
      //toast.error("something is wrong");
    }
  };

  function onEditMember(member) {
    setActiveMember(member.id);

    setPhone(member.phone);
    setEmail(member.email);
    setName(member.name);
    setOneLink(member.oneLink ?? true);
    setVLink(member.vLink ?? true);

    if (member.theme) {
      //console.log("onEditMember: Themes: ",JSON.stringify(member.theme))
      setSelectedTheme(member.theme);
    } else {
      setSelectedTheme(["light", "dark", "simple", "custom"]);
    }

    setEditMemberModal(true);
  }

  function onChangePassword(member) {
    setActiveUser(member);
    setActiveMember(member.id);
    setChangePasswordModal(true);
  }

  function onGiveReward(member) {
    setActiveUser(member);
    setActiveMember(member.id);
    setPoints(0);
    setGiveRewardPointModal(true);
  }

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone Number is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/update-user", {
          name: name,
          phone: phone,
          email: email,
          theme: selectedTheme,
          oneLink: oneLink,
          vLink: vLink,
          user_id: activeMember,
        });

        if (data.status === false) {
          swal("Update User", data.message, "error");
        } else {
          setEditMemberModal(false);
          swal("Update User", data.message, "success");

          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const onAddMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone Number is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }

    if (password === "") {
      error = true;
      errorObj.password = "Password is Required";
    } else if (password.length < 6) {
      error = true;
      errorObj.password = "Password must be 6 character long";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/add-user", {
          name: name,
          phone: phone,
          email: email,
          theme: selectedTheme,
          oneLink: oneLink,
          vLink: vLink,
          password: password,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Add User", data.message, "error");
        } else {
          setAddMemberModal(false);
          //toast.success(data.message);
          swal("Add User", data.message, "success");

          setPhone("");
          setEmail("");
          setName("");
          setPassword("");
          setSelectedTheme(["light", "dark", "simple", "custom"])
          setOneLink(true)
          setVLink(true)

          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const onChangePasswordSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (password === "") {
      error = true;
      errorObj.password = "Password is Required";
    } else if (password.length < 6) {
      error = true;
      errorObj.password = "Password must be 6 character long";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "admin/change-user-password",
          { user_id: activeMember, password: password }
        );

        if (data.status === false) {
          //toast.error(data.message);
          swal("Change User Password", data.message, "error");
        } else {
          setChangePasswordModal(false);
          //toast.success(data.message);
          swal("Change User Password", data.message, "success");

          setPassword("");
          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const onGiveRewardSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (points === "") {
      error = true;
      errorObj.points = "Points is Required";
    } else if (Number(points) <= 0) {
      error = true;
      errorObj.Points = "Points must be greater than zero";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/give-reward-points", {
          user_id: activeMember,
          points: points,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Give Tap1ce Points", data.message, "error");
        } else {
          setGiveRewardPointModal(false);
          //toast.success(data.message);
          swal("Give Tap1ce Points", data.message, "success");

          setPassword("");
          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) { }
    }
  };

  const initXlsData = async () => {
    var dummy = [];
    users.map((data, i) => {
      dummy.push({
        sr_no: i + 1,
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        points: data.points ?? 0,
        status: data.status,

        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  const changeUserStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("User", data.message, "success");

      var dummy = [...users];

      const index = dummy.findIndex((x) => x.id === userId);

      dummy[index].status = status;
      console.log("order index - " + index);

      setUsers(dummy);
    } else {
      swal("User", data.message, "error");
    }
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const readUploadFile = (e) => {
    e.preventDefault();

    console.log("readUploadFile");

    if (e.target.files) {
      console.log("readUploadFile file found");
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        console.log(json);

        //json to array
        var dummy = [];
        for (let i in json) {
          dummy.push({ ...json[i], id: i });
        }

        setBulkUserList(dummy);
        setBulkUserModal(true);

        e.target.value = null;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    } else {
      console.log("readUploadFile file not found");
    }
  };

  function onUserCheck(data, checked) {
    console.log("onUserCheck - " + checked);

    const dummy = [...selectedUser];

    if (checked) {
      dummy.push(data);
      setSelectedUser(dummy);

      if (dummy.length != bulkUserList.length) {
        setCheckAllUser(false);
      } else {
        setCheckAllUser(true);
      }

      console.log("onUserCheck item " + data + " added");
    } else {
      const memberIndex = dummy.findIndex((x) => x === data);

      if (memberIndex != -1) {
        dummy.splice(memberIndex, 1);
        console.log("onUserCheck item " + memberIndex + " removed");
      } else {
        //dummy.push(memberId);
        console.log("onUserCheck item " + memberIndex + " not found");
      }

      console.log("onUserCheck members - " + JSON.stringify(dummy));

      setSelectedUser(dummy);
      setCheckAllUser(false);
    }
  }

  function onAllUserCheck(checked) {
    console.log("onAllUserCheck - " + checked);

    if (checked) {
      let dummy = [];
      for (let key in bulkUserList) {
        dummy.push(Number(key));
      }

      setSelectedUser(dummy);
      console.log("onAllUserCheck members - " + JSON.stringify(dummy));
    } else {
      setSelectedUser([]);
      console.log("onAllUserCheck members removed");
    }

    setCheckAllUser(checked);
  }

  async function onBulkCreateUser() {
    let dummy = [];
    for (let key in selectedUser) {
      const data = bulkUserList[selectedUser[key]];
      const newData = {
        name: data.name ?? "",
        phone: data.phone ?? "",
        email: data.email ?? "",
        password: (data.password ?? "").toString(),
        company: data.company ?? "",
      };
      dummy.push(newData);
    }

    console.log("onBulkCreateUser members - " + JSON.stringify(dummy));

    //invite memner api call
    //dispatch(bulkInviteMemberAction(dummy,history));

    const { data } = await axiosInstance.post("admin/create-bulk-user", {
      users: dummy,
      send_email: sendEmail,
      productId: selectedProduct,
      couponCode: coupon
    });

    if (data.status) {
      swal(
        "Create Bulk User",
        data.message +
        "\n" +
        "Create: " +
        data.create +
        "\n" +
        "Already Exist: " +
        data.exist +
        "\n" +
        "Failed: " +
        data.failed,
        "success"
      );
    } else {
      swal("Create Bulk User", data.message, "error");
    }
  }

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Link to={"/user/" + d.id}>
            <span className="text-black">{d.name}</span>
            <br />
            <small>#{d.id}</small>
          </Link>
        );
      },
    },

    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        const d = row.original;
        return d.email ?? "";
      },
    },

    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => {
        const d = row.original;
        return d.phone ?? "";
      },
    },

    {
      Header: "Tap1ce Points",
      accessor: "points",
      Cell: ({ row }) => {
        const d = row.original;
        return d.points ?? "0";
      },
    },

    {
      Header: "Joined",
      accessor: "cratedAt",
      Cell: ({ row }) => {
        const d = row.original;
        return format(new Date(d.createdAt), "dd/MM/yyyy H:mma");
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const d = row.original;
        return d.status === "active" ? (
          <Badge variant="success light">
            Activated
            <span className="ml-1 fa fa-check"></span>
          </Badge>
        ) : d.status === "deactivate" ? (
          <Badge variant="danger light">
            Deactivated
            <span className="ml-1 fa fa-ban"></span>
          </Badge>
        ) : (
          <></>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={(e) => onEditMember(d)}>
                Edit Profile
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={(e) => onChangePassword(d)}>
                Change Password
              </Dropdown.Item>

              <Dropdown.Item as="button" onClick={(e) => onGiveReward(d)}>
                Give Tap1ce Points
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={(e) =>
                  changeUserStatus(
                    d.id,
                    d.status === "active" ? "deactivate" : "active"
                  )
                }
              >
                {d.status === "active" ? "Deactive Now" : "Active Now"}
              </Dropdown.Item>

              {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        className="modal fade"
        show={bulkUserModal}
        onHide={setBulkUserModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Select Users</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBulkUserModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label className="text-black font-w500">
                  Product
                </label>

                <Select
                  defaultValue={selectedProduct}
                  onChange={(option) => onChangeProduct(option.value)}
                  options={products.map((product, i) => {
                    return {
                      value: product.id,
                      label: product.name,
                    };
                  })}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                />
                {errors.product && (
                  <div className="text-danger fs-12">
                    {errors.product}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <label className="text-black font-w500">
                Coupon Code
              </label>
              <InputGroup className="">
                <FormControl
                  placeholder="Coupon Code"
                  aria-label="Coupon Code"
                  aria-describedby="basic-apply"
                  value={coupon}
                  style={{
                    borderColor: "hsl(0, 0%, 80%)",
                    height: 38,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4
                  }}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <Button
                  variant="primary"
                  className="btn btn-sm"
                  onClick={applyCouponCode}
                  id="button-apply"
                  style={{ borderRadius: "0px 5px 5px 0px", height: '38px', padding: 8 }}
                >
                  Apply
                </Button>
              </InputGroup>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <div className="custom-control custom-checkbox checkbox-success check-lg mr-3" style={{ zIndex: 0 }}>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkAll"
                      required=""
                      checked={checkAllUser}
                      onChange={(e) => onAllUserCheck(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkAll"
                    ></label>
                  </div>
                </th>
                <th>
                  <strong>NAME</strong>
                </th>

                <th>
                  <strong>Phone</strong>
                </th>

                <th>
                  <strong>Email</strong>
                </th>

                <th>
                  <strong>Password</strong>
                </th>

                <th>
                  <strong>Compnay</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {bulkUserList.map((invitation, i) => (
                <tr key={i}>
                  <td>
                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3" style={{ zIndex: 0 }}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"checkbox-" + i}
                        checked={selectedUser.includes(i)}
                        onChange={(e) => onUserCheck(i, e.target.checked)}
                        required=""
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox-" + i}
                      ></label>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {""}
                      <span className="w-space-no">{invitation.name}</span>
                    </div>
                  </td>
                  <td>{invitation.phone} </td>
                  <td>{invitation.email} </td>

                  <td>{invitation.password} </td>
                  <td>{invitation.company} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <div className="custom-control custom-checkbox checkbox-success check-lg mr-3" style={{ zIndex: 0 }}>
            <input
              type="checkbox"
              className="custom-control-input"
              checked={sendEmail}
              id="check-send-email"
              onChange={(e) => setSendEmail(e.target.checked)}
            />
            <label
              className="custom-control-label"
              style={{ paddingTop: "2px" }}
              htmlFor={"check-send-email"}
            >
              &nbsp; Send Email
            </label>
          </div>

          <Button
            variant="danger light"
            onClick={() => setBulkUserModal(false)}
          >
            Close
          </Button>
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={(e) => onBulkCreateUser()}
          >
            Create Users
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal fade"
        show={addMemberModal}
        onHide={setAddMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add User</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        {/* <input
                          type="text"
                          value={phone}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone Number"
                        /> */}

                        <PhoneInput
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          className="main"
                          value={phone}
                          required
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Password</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={password}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Themes</label>

                          {themeList.map((x) => (
                            <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={"theme-" + x.name}
                                checked={selectedTheme.includes(x.name)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (!selectedTheme.includes(x.name)) {
                                      setSelectedTheme((list) => [
                                        ...list,
                                        x.name,
                                      ]);
                                    }
                                  } else {
                                    setSelectedTheme((list) =>
                                      list.filter((t) => t != x.name)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label pl-2"
                                htmlFor={"theme-" + x.name}
                              >
                                {x.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">1Link</label>

                          <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="oneLink"
                              checked={oneLink}
                              onChange={(e) => {
                                setOneLink(e.target.checked);
                              }}
                            />
                            <label
                              className="custom-control-label ml-2 pl-2"
                              htmlFor="oneLink"
                            >
                              {oneLink ? "Enable" : "Disable"}
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Vehicle Bothering</label>

                          <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="vLink"
                              checked={vLink}
                              onChange={(e) => {
                                setVLink(e.target.checked);
                              }}
                            />
                            <label
                              className="custom-control-label ml-2 pl-2"
                              htmlFor="vLink"
                            >
                              {vLink ? "Enable" : "Disable"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={setEditMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit User</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        {/* <input
                          type="text"
                          value={phone}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone Number"
                        /> */}

                        <PhoneInput
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          className="main"
                          value={phone}
                          required
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Themes</label>

                          {themeList.map((x) => (
                            <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={"theme-" + x.name}
                                checked={selectedTheme.includes(x.name)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (!selectedTheme.includes(x.name)) {
                                      setSelectedTheme((list) => [
                                        ...list,
                                        x.name,
                                      ]);
                                    }
                                  } else {
                                    setSelectedTheme((list) =>
                                      list.filter((t) => t != x.name)
                                    );
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label pl-2"
                                htmlFor={"theme-" + x.name}
                              >
                                {x.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">1Link</label>

                          <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="oneLink"
                              checked={oneLink}
                              onChange={(e) => {
                                setOneLink(e.target.checked);
                              }}
                            />
                            <label
                              className="custom-control-label ml-2 pl-2"
                              htmlFor="oneLink"
                            >
                              {oneLink ? "Enable" : "Disable"}
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Vehicle Link</label>

                          <div className="custom-control custom-checkbox checkbox-success check-lg mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="vLink"
                              checked={vLink}
                              onChange={(e) => {
                                setVLink(e.target.checked);
                              }}
                            />
                            <label
                              className="custom-control-label ml-2 pl-2"
                              htmlFor="vLink"
                            >
                              {vLink ? "Enable" : "Disable"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={changePasswordModal}
        onHide={setChangePasswordModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onChangePasswordSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Set New Password</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setChangePasswordModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Set a new password for user{" "}
                      <b className="text-primary">{activeUser.email}</b>
                    </p>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        New Password
                      </label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={password}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setChangePasswordModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Change
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={giveRewardPointModal}
        onHide={setGiveRewardPointModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onGiveRewardSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Give Tap1ce Points</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setGiveRewardPointModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Enter Tap1ce point to user{" "}
                      <b className="text-primary">{activeUser.email}</b>
                    </p>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Points</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={points}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          min={0}
                          onChange={(e) => setPoints(e.target.value)}
                          placeholder="Points"
                        />
                        {errors.points && (
                          <div className="text-danger fs-12">
                            {errors.points}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setGiveRewardPointModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Give
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">Users</h4>
              </div>

              <div class="">
                <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (searchTimeout) {
                        clearTimeout(searchTimeout);
                      }
                      loadUsers();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </form>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <Button
                  className="btn btn-primary btn-sm mr-2"
                  onClick={(e) => setAddMemberModal(true)}
                >
                  Add User
                </Button>

                <Link
                  className="btn btn-primary btn-sm font-w600 mr-2"
                  id="plus"
                  onClick={upload}
                >
                  Import Excel
                </Link>

                <CSVLink
                  data={xlsData}
                  filename={"tap1ce-users.csv"}
                  className="btn btn-light btn-sm mr-2"
                  target="_blank"
                  asyncOnClick={true}
                  onClick={async (event, done) => {
                    console.log("You click the link");
                    await initXlsData();
                    done(); // 👍🏻 You are stopping the handling of component
                  }}
                >
                  Export
                </CSVLink>

                <a
                  className="btn btn-light btn-sm"
                  target="_black"
                  href="https://comket.in/tap1ce/bulk-add-user.xlsx"
                  download
                >
                  Bulk Upload Sample
                </a>

                <input
                  id="selectFile"
                  accept=".csv, .xls, .xlsx, text/csv, application/csv,
text/comma-separated-values, application/csv, application/excel,
application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  hidden
                  type="file"
                  onChange={readUploadFile}
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : users.length == 0 ? (
                <p>No User Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={users}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Users;
