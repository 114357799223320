import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import MultiSelect from "./MultiSelect";
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";

import { format } from "date-fns";

//const Orders = () => {
const Coupons = ({ props, history }) => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState([]);
  const [packs, setPacks] = useState([]);
  const [packOptions, setPackOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: "", counter: "", qautity: "" });

  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [type, setType] = useState("percentage");

  const [restricted, setRestricted] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [createCouponModal, setCreateCouponModal] = useState(false);

  const sort = 500;
  let pagination = Array(Math.ceil(coupons.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadCoupons();
    loadPacks();
  }, [dispatch]);

  useEffect(() => {
    if (coupons) {
      setLoading(false);
      setTableData(coupons.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [coupons]);

  const loadPacks = async () => {
    const { data } = await axiosInstance.get("products");

    if (data.status) {
      //setPacks(data.packs);

      var dummy = [];
      data.packs.map((data, i) => {
        dummy.push({
          value: data.id,
          label: data.type + " " + data.name,
        });
      });
      setPackOptions(dummy);
    }
  };

  const loadCoupons = async () => {
    const { data } = await axiosInstance.post("admin/coupons");

    if (data.status) {
      setCoupons(data.coupons);
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(coupons.slice(i * sort, (i + 1) * sort));
  };

  const onCreateCoupon = async (event) => {
    event.preventDefault();

    try {
      const { data } = await axiosInstance.post("admin/create-coupon", {
        code: code,
        discount: discount,
        type: type,
        restricted: restricted,
        packs: restricted ? packs :[],
        start_date: startDate,
        end_date: endDate,
      });

      if (data.status === false) {
        //toast.error(data.message);
        swal("Create Coupon", data.message, "error");
      } else {
        //toast.success(data.message);
        setCreateCouponModal(false);
        swal("Create Coupon", data.message, "success");
        setCode("");
        setDiscount("");
        setType("percentage");
        setRestricted(true);
        setPacks([]);
        setStartDate("");
        setEndDate("");
        loadCoupons();
      }
    } catch (error) {
      swal("Create Coupon", error, "error");
    }
  };

  const onDelete = async (coupon_id) => {
    //history.push("/voucher-codes/"+campaign.id);

    const { data } = await axiosInstance.post(
        "admin/delete-coupon",
        {
            coupon_id:coupon_id,
        }
     );

     if(data.status){
        swal('Coupon', data.message, "success");
        loadCoupons();
     }else{
        swal('Coupon', data.message, "error");
     }

  };

  const packsName = (packs) => {

    var packName = "";

    packs.map((data, i) => {
      packName = packName+data.type+" "+data.name+ ", ";
    });

    return packName;

  }

  return (
    <>
      <Modal
        className="modal fade"
        show={createCouponModal}
        onHide={setCreateCouponModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onCreateCoupon}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Create Coupon</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setCreateCouponModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row mb-3">
                      <div className="form-group col-6">
                        <label className="text-black font-w500">Code</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            value={code}
                            className="form-control"
                            autocomplete="off"
                            required="required"
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Code"
                          />
                          {errors.code && (
                            <div className="text-danger fs-12">
                              {errors.code}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>

                      <div className="form-group col-6">
                        <label className="text-black font-w500">Discount</label>
                        <div className="contact-name">
                          <input
                            type="number"
                            value={discount}
                            className="form-control"
                            autocomplete="off"
                            name="title"
                            required="required"
                            onChange={(e) => setDiscount(e.target.value)}
                            placeholder="Discount"
                          />
                          {errors.discount && (
                            <div className="text-danger fs-12">
                              {errors.discount}
                            </div>
                          )}

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="form-group col-12">
                        <label className="text-black font-w500">Type</label>
                        <select
                          className="form-control"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          defaultValue="No"
                        >
                          <option value="percentage">Percentage</option>
                          <option value="fixed">Fixed</option>
                        </select>
                      </div>

                     {/*  <div className="form-group col-6">
                        <label className="text-black font-w500">
                          Restricted
                        </label>
                        <select
                          className="form-control"
                          value={restricted ? "Yes" : "No"}
                          onChange={(e) =>
                            setRestricted(e.target.value == "Yes")
                          }
                          defaultValue="No"
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div> */}
                    </div>

                    {restricted ? (
                      <div className="form-group">
                        <label className="text-black font-w500">Packs</label>

                        <MultiSelect
                          options={packOptions}
                          onChange={(selected) => {
                            console.log(selected);
                            if (selected) {
                              var dummy = [];
                              selected.map((data, i) => {
                                dummy.push(data.value);
                              });
                              setPacks(dummy);
                            } else {
                              setPacks([]);
                            }
                          }}
                        ></MultiSelect>
                      </div>
                    ) : (
                      <></>
                    )}

<div className="row mb-3">
                    <div className="form-group col-6">
                      <label className="text-black font-w500">Start Date</label>

                      <DatePicker onChange={(date) => {
                        setStartDate(date);
                      }}/>

                      {/* <RangeDatePicker
                        startText="Start"
                        endText="End"
                        startPlaceholder="Start Date"
                        endPlaceholder="End Date"
                        onChange={(date) => {
                          // Day.js object
                          console.log(date);
                      
                          // to normal Date object
                          //console.log(date.toDate());
                        }
                      }
                      /> */}
                    </div>

                    <div className="form-group col-6">
                      <label className="text-black font-w500">End Date</label>

                      <DatePicker onChange={(date) => {
                        setEndDate(date);
                      }}/>

                      {/* <RangeDatePicker
                        startText="Start"
                        endText="End"
                        startPlaceholder="Start Date"
                        endPlaceholder="End Date"
                        onChange={(date) => {
                          // Day.js object
                          console.log(date);
                      
                          // to normal Date object
                          //console.log(date.toDate());
                        }
                      }
                      /> */}
                    </div>
                    </div>


                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setCreateCouponModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Coupons</h4>

              <Link
                to="#"
                className="btn btn-light font-w600 mr-2"
                onClick={() => setCreateCouponModal(true)}
              >
                Create Coupon
              </Link>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : coupons.length == 0 ? (
                <p>No Coupons Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Code</th>
                          <th>Type</th>
                          <th>Discount</th>
                        
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Created At</th>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={i}>
                            {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                            <td>{i + 1}</td>
                            <td>{d.code}</td>
                            <td>{d.type}</td>
                            <td>{d.discount}</td>
                            {/* <td>{d.restricted? packsName(d.packs) :"All Pack"}</td> */}
                            <td>
                              {format(new Date(d.startDate), "dd/MM/yyyy")}
                            </td>
                            <td>{format(new Date(d.endDate), "dd/MM/yyyy")}</td>
                            <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onDelete(d.id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Code</th>
                          <th>Type</th>
                          <th>Discount</th>
                        
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Created At</th>
                          <td>Action</td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {coupons.length < (activePage + 1) * sort
                          ? coupons.length
                          : (activePage + 1) * sort}
                        &nbsp;of {coupons.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Coupons;
