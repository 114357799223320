import React, { useState, useEffect, Fragment } from "react";
import { Badge, Spinner, Dropdown, } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosInstance from "../../services/AxiosInstance";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import DTable from "../components/Table";

//const Orders = () => {
const vLinkUsers = () => {
  const [users, setUsers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(25);
  const pageSizes = [10, 25, 50, 100, 1000, 10000];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadUsers();
  }, [page, pageSize]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    if (users) {
      setLoading(false);
    }
  }, [users]);

  const loadUsers = async () => {
    const { data } = await axiosInstance.post("vlink/users", {
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setUsers(data.users);
      var maxPage2 = Math.ceil(data.total / pageSize);
      setMaxPage(maxPage2);
    }
  };

  const initXlsData = async () => {
    var dummy = [];
    users.map((data, i) => {
      dummy.push({
        sr_no: i + 1,
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        points: data.points ?? 0,
        vLink: "https://vlink.tap1ce.uk/" + data.vLinkName,
        status: data.status,

        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Fragment>
            <span className="text-black">{d.name}</span>
            <br />
            <small>#{d.id}</small>
          </Fragment>
        );
      },
    },

    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        const d = row.original;
        return d.email ?? "";
      },
    },

    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => {
        const d = row.original;
        return d.phone ?? "";
      },
    },

    {
      Header: "Tap1ce Points",
      accessor: "points",
      Cell: ({ row }) => {
        const d = row.original;
        return d.points ?? "0";
      },
    },

    {
      Header: "Joined",
      accessor: "cratedAt",
      Cell: ({ row }) => {
        const d = row.original;
        return format(new Date(d.createdAt), "dd/MM/yyyy H:mma");
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const d = row.original;
        return d.status === "active" ? (
          <Badge variant="success light">
            Activated
            <span className="ml-1 fa fa-check"></span>
          </Badge>
        ) : d.status === "deactivate" ? (
          <Badge variant="danger light">
            Deactivated
            <span className="ml-1 fa fa-ban"></span>
          </Badge>
        ) : (
          <></>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={(e) => downloadQR(d)}>
                Download QR
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  async function downloadQR(d) {
    const downloadLink = document.createElement("a");
    downloadLink.href = "api/vlink/qr/" + d.id;
    downloadLink.download = d.id;  // Specify the name of the downloaded file
    document.body.appendChild(downloadLink); // Append to the DOM (needed for some browsers)
    downloadLink.click();  // Trigger the download
    document.body.removeChild(downloadLink);
  }

  return (
    <>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">vLink Users</h4>
              </div>

              <div class="">
                <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (searchTimeout) {
                        clearTimeout(searchTimeout);
                      }
                      loadUsers();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </form>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <CSVLink
                  data={xlsData}
                  filename={"tap1ce-users.csv"}
                  className="btn btn-light btn-sm mr-2"
                  target="_blank"
                  asyncOnClick={true}
                  onClick={async (event, done) => {
                    await initXlsData();
                    done(); // 👍🏻 You are stopping the handling of component
                  }}
                >
                  Export
                </CSVLink>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : users.length == 0 ? (
                <p>No User Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={users}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default vLinkUsers;
