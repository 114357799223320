import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";
import Member from "./components/Dashboard/Member";
import Cards from "./components/Dashboard/Cards";
import Profiles from "./components/Dashboard/Profiles";

import Coupons from './pages/Coupons';
import AddProfile from "./pages/add-profile/add-profile";
import EditProfile from "./pages/edit-profile/edit-profile";

import Trials from './pages/Trials';

/// Pages
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'


import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import Corporates from './pages/Corporates';
import CorporateDetails from './pages/CorporateDetails';
import Users from './pages/Users';
import CorporateMembers from './pages/CorporateUsers';

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: "my-profile", component: MyProfile },
    { url: "members", component: Member },
    { url: "cards", component: Cards },
    { url: "profiles", component: Profiles },
    { url: "add-profile", component: AddProfile },
    { url: "edit-profile/:id", component: EditProfile },
    { url: 'orders', component: Orders },
     { url: 'coupons', component: Coupons },
     { url: 'trials', component: Trials },

    { url: 'order/:id', component: OrderDetails },
    { url: 'corporates', component: Corporates },
    { url: 'corporate-members', component: CorporateMembers },
    
    { url: 'corporate/:id', component: CorporateDetails },
    { url: 'users', component: Users },
    



    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'forgot-password', component: ForgotPassword },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
    
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
