import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";
import { format } from "date-fns";

import { Table, Pagination, Badge,Dropdown,Spinner ,Button} from "react-bootstrap";

import { CSVLink } from "react-csv";

const CorporateDetails = ({ match, history }) => {

   const dispatch = useDispatch();
   const [corporate, setCorporate] = useState(null);
   const [members, setMembers] = useState([]);
   const [xlsData, setXlsData] = useState([]);

   const [cards, setCards] = useState("");
   const [allotCardLoading, setAllotCardLoading] = useState(false);

   const [activeToggle, setActiveToggle] = useState("members");
   const [sendMessage, setSendMessage] = useState(false);

   const [replay, setReplay] = useState(false);

   const options = {
      settings: {
         overlayColor: "#000000",
      },
   };


   useEffect(() => {
   
      // loadCorporateDetails(match.params.id);
      // loadMembers(match.params.id);
      
   }, [dispatch]);


   

   useEffect(() => {
   
      loadCorporateDetails(match.params.id);
      loadMembers(match.params.id);
      
   }, [match]);

    const loadCorporateDetails = async (userId) => {
      const { data } = await axiosInstance.get(
         "admin/user/"+userId
       );
 
       if(data.status){
         setCorporate(data.user);
         document.getElementById("nav_title").innerHTML = data.user.name? data.user.name :data.user.email;
       }
    }

    const loadMembers = async (userId) => {
      const { data } = await axiosInstance.post(
         "admin/corporate-members",
         {
            corporate_id:userId,
            status:"accepted"
         }
       );
 
       if(data.status){
         setMembers(data.invitations);
       }
    }

    const allotCard = async (e) => {
      e.preventDefault();
      setAllotCardLoading(true);

      
      const { data } = await axiosInstance.post(
         "admin/allot-cards",
         {
            corporate_id:match.params.id,
            cards:cards
         }
       );
 
       if(data.status){
         swal('Allot Cards', data.message, "success");
         setCards("");
         loadCorporateDetails(match.params.id);
       }else{
         swal('Allot Cards', data.message, "error");
       }

      setAllotCardLoading(false);
    }
    

    function inviteStatus(status){

      if(status == "sent"){
         return "text-warning";
      }else if(status == "accepted"){
         return "text-success";
      }else{
         return "text-danger";
      }

    }


    const memberAddress = (member)=>{

      var address = "";

      if(member.address){
         if(member.address.addressLine1){
         
            address = (member.address.addressLine1??"")+", "+
            (member.address.addressLine2??"")+", "+
            (member.address.city??"")+", "+
            (member.address.state??"")+", "+
            (member.address.country??"")+" - "+
            (member.address.zip??"");
         
         }else{
            address = "Not Available";
         }
      }else{
         address = "Not Available";
      }


      return address;

    }

    const initXlsData = async () => {

      var dummy = [];
      members.map((data,i)=>{

         var address = "";
         if(data.address){
            if(data.address.addressLine1){
            
               address = (data.address.addressLine1??"")+", "+
               (data.address.addressLine2??"")+", "+
               (data.address.city??"")+", "+
               (data.address.state??"")+", "+
               (data.address.country??"")+" - "+
               (data.address.zip??"");
            
            }else{
               address = "Not Available";
            }
         }
      
         dummy.push({
            sr_no:i+1,
            id:data.id,
            name:data.name,
            email:data.email,
            department:data.department,
            designation:data.designation,
            card_id:data.cardId,
            address:address,
            joined:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
         });
      });


      setXlsData(dummy);


   }
   
   
   const changePrintStatus = async (invitationId,status) => {

      const { data } = await axiosInstance.post(
         "admin/print-status",
         {
            invitation_id:invitationId,
            status:status
         }
      );

      if(data.status){
         swal('Print Card', data.message, "success");

         var dummy = [...members];
         
         const index = dummy.findIndex(
            (x) => x.id === invitationId
         );

         dummy[index].isPrinted = status;
         console.log("print index - "+index);

         setMembers(dummy);
   
      }else{
         swal('Print Card', data.message, "error");
      }

   }




   return (
      <Fragment>
         
         <div className="row">
            <div className="col-lg-12">
            {corporate == null ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:
            
            (
               <>
               
               <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                       
                     </div>
                     <div className="profile-info">
                        <div className="profile-photo">
                         
                        </div>
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                {corporate.name}
                              </h4>
                              <p>{corporate.email}</p>
                           </div>
                          
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="row">
            <div className="col-xl-4">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-statistics mb-5">
                        <div className="text-center">
                           <div className="row">
                              <div className="col">
                                 <h3 className="m-b-0">{corporate.cards}</h3>
                                 <span>Available Cards</span>
                              </div>
                              
                              <div className="col">
                                 <h3 className="m-b-0">{members.length}</h3>
                                 <span>Members</span>
                              </div>
                           </div>

                           <div className="row mt-4">

                           <div className="col">
                           <form onSubmit={allotCard}>
                           <div className="form-group">
                                        <label className="mb-2 d-flex flex-wrap align-items-center"><strong className="mr-auto">Allot Cards</strong> </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={cards}
										            placeholder="Enter Cards"
                                          required
                                            onChange={(e) =>
                                                setCards(e.target.value)
                                            }
                                        />
                                        
                           </div>


                           <div className="text-center mt-2">
                                  {allotCardLoading ?<Spinner animation="border" variant="primary" /> :
                                  <button
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                    >
                                      Allot
                                    </button>}
                           </div>

                           </form>
                           </div>

                           </div>
                          
                        </div>
                       

               
                     </div>
                    
                  </div>
               </div>
            </div>
            <div className="col-xl-8">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                           <ul className="nav nav-tabs">
                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("members")}
                              >
                                 <Link
                                    to="#members"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "members"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Members
                                 </Link>
                              </li>
                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("about")}
                              >
                                 <Link
                                    to="#about"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "about"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    About
                                 </Link>
                              </li>
                             
                           </ul>
                           <div className="tab-content">
                              <div
                                 id="members"
                                 className={`tab-pane fade ${
                                    activeToggle === "members"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="my-post-content pt-3">
                                   
                                   <div className="mb-3 text-right">

                                   <CSVLink
                                    data={xlsData}
                                    filename={corporate.name+"'s Members.csv"}
                                    className="btn btn-primary"
                                    target="_blank"
                                    asyncOnClick={true}
                                    onClick={async (event, done) => {
                                       console.log("You click the link");
                                       await initXlsData();
                                       done(); // 👍🏻 You are stopping the handling of component
                                    }}

                                    >
                                    Export
                                    </CSVLink>

                                   </div>

                                 <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>NAME</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>
                              <th>
                                 <strong>Department</strong>
                              </th>

                              <th>
                                 <strong>Designation</strong>
                              </th>

                              <th>
                                 <strong>Address</strong>
                              </th>

                              <th>
                                 <strong>Joined</strong>
                              </th>

                              <th>
                                 <strong>Card Printed</strong>
                              </th>

                              <th>
                                 <strong>Status</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>

						{
          members.map((invitation,i) => (

                           <tr key={i}>
                             
                              <td>
                                 <strong>{i+1}</strong>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                  {""}
                                    <span className="w-space-no">
                                       {invitation.name}
                                    </span>
                                 </div>
                              </td>
                              <td>{invitation.email} </td>
                              <td>{invitation.department} </td>
                              <td>{invitation.designation} </td>
                              <td>{memberAddress(invitation)} </td>
                              <td>{ format(new Date(invitation.createdAt), "dd/MM/yyyy H:mma")}</td>

                              <td>{invitation.isPrinted?"Yes":"No"} </td>
                              <td>
                                 
                                 <div className="d-flex align-items-center">
                                    <i className={"fa fa-circle "+inviteStatus(invitation.status)+" mr-1"}></i>{" "}
                                    {invitation.status}
                                 </div>
                                    
                              </td>

                              <Dropdown>
                                             <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                             >
                                                <svg
                                                   width="24px"
                                                   height="24px"
                                                   viewBox="0 0 24 24"
                                                   version="1.1"
                                                >
                                                   <g
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                   >
                                                      <rect
                                                         x="0"
                                                         y="0"
                                                         width="24"
                                                         height="24"
                                                      ></rect>
                                                      <circle
                                                         fill="#000000"
                                                         cx="5"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="12"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="19"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                   </g>
                                                </svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={(e)=>changePrintStatus(invitation.id,true)}>
                                                   Card is Printed
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changePrintStatus(invitation.id,false)}>
                                                   Card is not Printed
                                                </Dropdown.Item>
                                               
                                              
                                             </Dropdown.Menu>
                                          </Dropdown>
                             
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>

                                 </div>
                              </div>
                              <div
                                 id="about"
                                 className={`tab-pane fade ${
                                    activeToggle === "about"
                                       ? "active show"
                                       : ""
                                 }`}
                              >


                                 <div className="row mt-4">

                                 <div className="col mb-4">
							<h6>Address:</h6>
							<div> <strong>{corporate.address.addressLine1} {corporate.address.addressLine2}</strong> </div>
							<div>{corporate.address.lanmark}</div>
                     <div>{corporate.address.city}</div>
							<div>{corporate.address.state}</div>
							<div>{corporate.address.country} - {corporate.address.zip}</div>
						</div>

                                 </div>


                                
                              </div>
                              <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                    activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                   
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


               </>
           )}
         </div>
         </div>
      </Fragment>
   );
};

export default CorporateDetails;
