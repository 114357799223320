import React, { useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
import swal from "sweetalert";
import {nanoid} from 'nanoid';

import { useDispatch,useSelector } from "react-redux";

import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	Modal,
	ProgressBar,
 } from "react-bootstrap";



// Components
import CardItem from "../../components/card-item/card-item";

//Actions
import { getAllCards } from '../../../store/actions/cardActions';




const CardPage = ({ props, history }) => {
	
	
	const dispatch = useDispatch();

	const getAllCard = useSelector((state) => state.getAllCard);
	const { cards, allCardLoading, allCardError } = getAllCard;

	//const { invitations} = getInvitations;

	useEffect(() => {
		dispatch(getAllCards());
	  }, [dispatch]);

    
	  useEffect(() => {
		if (cards) {
		  console.log("cards founds");
		  //toast.success("cards founds");
		}
	
		if (allCardLoading) {
		  console.log("allCardLoading");
		} else {
		  console.log("allCardLoading false");
		}
	
		if (allCardError) {
		  console.log("allCardError");
		  //toast.error(allCardError);
		}
	  }, [cards, allCardLoading, allCardError]);
    

   
    
    return(
        <>
			
			

			<div class="row pb-5 mb-4">
                        {!allCardLoading && cards ? (
                          cards.map((card,i) => (
                            <CardItem key={card._id} card={card} isEven={ (i == 0) || (i % 2  == 0)}></CardItem>
                          ))
                        ) : (
                          <div>Loading cards...</div>
                        )}
                      </div>

			

            
        </>
    );     
}

export default CardPage;