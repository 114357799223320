import "./edit-profile.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Row,
  Col,
  Spinner,
  FloatingLabel,
} from "react-bootstrap";

//import { Nav,Tab,Tabs,TabContainer,TabContent,TabPane} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import swal from "sweetalert";

// Actions
import {
  getProfileDetails,
  saveProfile,
  resetSaveProfile,
} from "../../../store/actions/cardActions";

import profileTypeList from "../../../utils/profile-type";

const EditProfilePage = ({ match, history }) => {

  const [profileName, setProfileName] = useState("");

  const [prefix, setPrefix] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");

  const [link, setLink] = useState("");
  const [profileType, setProfileType] = useState(0);

  //list
  const [websites, setWebsites] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [skype, setSkype] = useState("");
  const [hangouts, setHangouts] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  const [bio, setBio] = useState("");
  const [service, setService] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [progress, setProgress] = useState(false);

  //website type
  const websiteType = ["Home", "Work"];
  const emailType = ["Home", "Work", "Other"];
  const phoneType = ["Mobile", "Home", "Work", "Other"];
  const addressType = ["Home", "Work"];

  const dispatch = useDispatch();

  const profileDetails = useSelector((state) => state.getProfileDetails);
  const { profileLoading, profileError, profile } = profileDetails;

  const saveProfileSelector = useSelector((state) => state.saveProfile);
  const { saveProfileLoading, isProfileSaved, saveProfileError } =
    saveProfileSelector;

  const { state } = useLocation();

  console.log("state 1 - "+state);
  console.log("state 1 - "+JSON.stringify(state));


  useEffect(() => {
  
    console.log("state - "+state);
    console.log("state - "+JSON.stringify(state));
    if (state) {
      
    }
  }, []);



  useEffect(() => {
   
    dispatch(getProfileDetails(match.params.id));
    
  }, [match]);

  useEffect(() => {
    if (profile) {
      console.log("profile - " + JSON.stringify(profile));

      if (profile) {

        // if(profile.namePrefix === "undefined"){
        //   profile.namePrefix ="";
        // }

        const profileTypeIndex = profileTypeList.findIndex(
          (x) => x.type === profile.type
        );

        if (profileTypeIndex != -1) {
          setProfileType(profileTypeIndex);
        }

        setProfileName(profile.profileName ? profile.profileName : "");

        if (profile.type === "business") {
          setName(profile.name ? profile.name : "");

          if(profile.namePrefix){
            setPrefix(profile.namePrefix != "undefined" ? profile.namePrefix : "");
          }

          
          setCompany(profile.company ? profile.company : "");

         

          setDesignation(profile.designation ? profile.designation : "");

          //add website
          const websiteData = profile.websites.map(function (x, i) {
            return { type: x.type, link: x.link };
          });
          setWebsites(websiteData);

          //add phone
          const phoneData = profile.phones.map(function (x, i) {
            return { type: x.type, phone: x.phone };
          });
          setPhones(phoneData);

          //add emails
           const emailData = profile.emails.map(function (x, i) {
            return x;
          });
          setEmails(emailData);

          //add address
          const addressData = profile.addresses.map(function (x, i) {
            return x;
          });
          setAddresses(addressData);

          const twitterData = profile.socialAccounts.find(
            (x) => x.type === "twitter"
          );
          if (twitterData) {
            setTwitter(twitterData.link);
          }

          const skypeData = profile.socialAccounts.find(
            (x) => x.type === "skype"
          );
          if (skypeData) {
            setSkype(skypeData.link);
          }

          const hangoutsData = profile.socialAccounts.find(
            (x) => x.type === "hangouts"
          );
          if (hangoutsData) {
            setHangouts(hangoutsData.link);
          }

          const linkedinData = profile.socialAccounts.find(
            (x) => x.type === "linkedin"
          );
          if (linkedinData) {
            setLinkedin(linkedinData.link);
          }

          const facebookData = profile.socialAccounts.find(
            (x) => x.type === "facebook"
          );
          if (facebookData) {
            setFacebook(facebookData.link);
          }

          const instagramData = profile.socialAccounts.find(
            (x) => x.type === "instagram"
          );
          if (instagramData) {
            setInstagram(instagramData.link);
          }
        } else {
          setLink(profile.link ? profile.link : "");
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    if (saveProfileLoading) {
      setProgress(true);
    }

    if (saveProfileError) {
      setProgress(false);
      
      swal('Save Profile', saveProfileError, "error");
    }

    if (isProfileSaved) {
      setProgress(false);
      //saveProfileError
      swal('Save Profile', "profile saved!", "success");
      dispatch(resetSaveProfile());


      //history.push({pathname:'/cards/'+match.params.id,});

      //console.log("redirect to cardId" + cardId)
      history.push("/profiles");
      // if (cardId != "") {
      //   console.log("redirect to /cards/" + cardId)
      //   history.push("/cards/" + cardId);
       
      // } else {
       
      //   console.log("redirect to cards")
      //   history.push({ pathname: `/my-account`, state: { menu: "cards" } });
      // }


    }
  }, [saveProfileLoading, isProfileSaved, saveProfileError]);


  function fullName(){

    var name = profile.name;

    if(!(profile.namePrefix === "undefined") && profile.namePrefix){
      name = profile.namePrefix +" "+name;
    }
    return name;
  }

  const onShowMore = async (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const onAddWebsite = async (e) => {
    e.preventDefault();

    if (websites.length < 5) {
      setWebsites((websites) => [
        ...websites,
        { type: websiteType[0], link: "" },
      ]);
    } else {
      
      swal('Add Websites', "You can't add more then 5 websites", "warning");
      
    }
  };

  const onRemoveWebsite = async (value) => {
    const newArray = websites.filter(function (element) {
      return element != value;
    });
    setWebsites(newArray);
  };

  const onChangeWebsiteType = async (i, type) => {
    websites[i].type = type;
    setWebsites((websites) => [...websites]);
  };

  const onChangeWebsite = async (i, link) => {
    websites[i].link = link;
    setWebsites((websites) => [...websites]);
  };

  const onAddEmail = async (e) => {
    e.preventDefault();

    if (emails.length < 5) {
      setEmails((emails) => [...emails, { type: emailType[0], email: "" }]);
    } else {
      
      swal('Add Email', "You can't add more then 5 email", "warning");
    }
  };

  const onRemoveEmail = async (value) => {
    const newArray = emails.filter(function (element) {
      return element != value;
    });
    setEmails(newArray);
  };

  const onChangeEmailType = async (i, type) => {
    emails[i].type = type;
    setEmails((emails) => [...emails]);
  };

  const onChangeEmail = async (i, email) => {
    emails[i].email = email;
    setWebsites((emails) => [...emails]);
  };

  const onAddPhone = async (e) => {
    e.preventDefault();

    if (phones.length < 5) {
      setPhones((phones) => [...phones, { type: phoneType[0], phone: "" }]);
    } else {
     
      swal('Add Phone Number', "You can't add more then 5 phone number", "warning");
    }
  };

  const onRemovePhone = async (value) => {
    const newArray = phones.filter(function (element) {
      return element != value;
    });
    setPhones(newArray);
  };

  const onChangePhoneType = async (i, type) => {
    phones[i].type = type;
    setPhones((phones) => [...phones]);
  };

  const onChangePhone = async (i, phone) => {
    phones[i].phone = phone;
    setPhones((phones) => [...phones]);
  };


  //address
    const onAddAddress = async (e) => {
    e.preventDefault();

    if (addresses.length < 5) {
      setAddresses((addresses) => [...addresses, { type: addressType[0], street: "",city:"",state:"",country:"",zip:"" }]);
    } else {
      
      swal('Add Address', "You can't add more then 5 address", "warning");
    }
  };

  const onRemoveAddress = async (value) => {
    const newArray = addresses.filter(function (element) {
      return element != value;
    });
    setAddresses(newArray);
  };

  const onChangeAddressType = async (i, type) => {
    addresses[i].type = type;
    setAddresses((addresses) => [...addresses]);
  };

  const onChangeStreet = async (i, value) => {
    addresses[i].street = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCity = async (i, value) => {
    addresses[i].city = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeState = async (i, value) => {
    addresses[i].state = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeZip = async (i, value) => {
    addresses[i].zip = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCountry = async (i, value) => {
    addresses[i].country = value;
    setAddresses((addresses) => [...addresses]);
  };

  const onSaveProfile = async (e) => {
    e.preventDefault();
    setProgress(true);

    if (profile) {
      const data = {

        //update
        profile_name: profileName,
        typeId: profileType,
        type: profileTypeList[profileType].type,
        link: link,

        id: profile.id,
        name_prefix: prefix,
        name: name,
        company: company,
        designation: designation,
        websites: websites,
        emails: emails,
        phones: phones,
        addresses: addresses,
        social_accounts: [
          {
            type: "skype",
            link: skype,
          },
          {
            type: "hangouts",
            link: hangouts,
          },
          {
            type: "twitter",
            link: twitter,
          },
          {
            type: "Linkedin",
            link: linkedin,
          },
          {
            type: "facebook",
            link: facebook,
          },
          {
            type: "instagram",
            link: instagram,
          },
        ],
      };

      return dispatch(saveProfile(data));
    } else {
      setProgress(false);
     
      swal('Oops', "Profile record not found", "error");
      history.push("/profiles");
      
    }
  };

  function formBlock() {
    if (!profile) {
      return (
        <div className="container text-center">
          <h5>Loafing Profile...</h5>
        </div>
      );
    }

    if (profileType == 0) {
      return (
        <>
          <div class="title-text py-2">
            <h5 className="h5 font-weight-bold">Personal Information</h5>
          </div>


          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={4} controlId="formName">
              <Form.Label>Prefix</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Prefix"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={8} controlId="formPhone">
              <Form.Label className="required-field">Name</Form.Label>
              <Form.Control
                className="main"
                placeholder="Enter Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6} controlId="formCompany">
              <Form.Label className="required-field">Company / Organization</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="formDesignation">
              <Form.Label className="required-field">Designation / Role / Title</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Enter Designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          {showMore ? (
            <>
              <div class="title-text py-2">
                <h5 className="h5 font-weight-bold">Contact Information</h5>
              </div>

              <Row className="mb-3">
                <Col>
                  <Form.Label>Website</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddWebsite} className="btn btn-sm btn-blue-2">
                    Add Website
                  </Link>
                </Col>
              </Row>

              {websites.map((website, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={websites[i].type}
                      onChange={(e) => {
                        onChangeWebsiteType(i, e.target.value);
                      }}
                    >
                      {websiteType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="Website"
                      value={website.link}
                      onChange={(e) => onChangeWebsite(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveWebsite(website)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Email</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddEmail} className="btn btn-sm btn-blue-2">
                    Add Email
                  </Link>
                </Col>
              </Row>

              {emails.map((email, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={emails[i].type}
                      onChange={(e) => {
                        onChangeEmailType(i, e.target.value);
                      }}
                    >
                      {emailType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="email"
                      value={email.email}
                      onChange={(e) => onChangeEmail(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveEmail(email)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <Row className="mb-3">
                <Col>
                  <Form.Label>Phone</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddPhone} className="btn btn-sm btn-blue-2">
                    Add Phone
                  </Link>
                </Col>
              </Row>

              {phones.map((phone, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={phones[i].type}
                      onChange={(e) => {
                        onChangePhoneType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="phone"
                      value={phone.phone}
                      onChange={(e) => onChangePhone(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemovePhone(phone)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}


<Row className="mb-3">
                <Col>
                  <Form.Label>Address</Form.Label>
                </Col>

                <Col className="text-right">
                  <Link onClick={onAddAddress} className="btn btn-sm btn-blue-2">
                    Add Address
                  </Link>
                </Col>
              </Row>

{addresses.map((address, i) => (
                <Row className="mb-3" key={i}>
                  <Form.Group as={Col} lg={4}>
                    <Form.Control
                      as="select"
                      className="main"
                      value={addresses[i].type}
                      onChange={(e) => {
                        onChangeAddressType(i, e.target.value);
                      }}
                    >
                      {phoneType.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Col lg={6}>
                  <Form.Group >
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="street"
                      value={address.street}
                      onChange={(e) => onChangeStreet(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group >
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="city"
                      value={address.city}
                      onChange={(e) => onChangeCity(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group >
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="state"
                      value={address.state}
                      onChange={(e) => onChangeState(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group >
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="country"
                      value={address.country}
                      onChange={(e) => onChangeCountry(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group >
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="zip"
                      value={address.zip}
                      onChange={(e) => onChangeZip(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  </Col>

                  <Col lg={2} className="text-center">
                    <Link
                      onClick={(e) => onRemoveAddress(address)}
                      className="btn btn-sm btn-danger"
                    >
                      Remove
                    </Link>
                  </Col>
                </Row>
              ))}

              <div class="title-text py-2">
                <h5 className="h5 font-weight-bold">
                  Social Media Information
                </h5>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Skype</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Skype"
                    value={skype}
                    onChange={(e) => setSkype(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>Hangouts</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Hangouts"
                    value={hangouts}
                    onChange={(e) => setHangouts(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Twitter</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Twitter"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>LinkedIn</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@LinkedIn"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="@Facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Label>Instagram</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="@Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}

          <div className="text-right">
            <Link onClick={onShowMore} className="btn btn-sm btn-blue-2">
              {!showMore ? "Show More" : "Show Less"}
            </Link>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div class="title-text py-2 mb-4">
            <h5 className="h5 font-weight-bold">
              {profileTypeList[profileType].name}
            </h5>
            <p>Enter the details below to create your card profile.</p>
          </div>


          
          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label className="required-field">{profileTypeList[profileType].inputType}</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder={profileTypeList[profileType].hint}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        </>
      );
    }
  }

  return (
    <>
      <section class="section bg-gray">
        <div class="container-fluid p-0">
          {profileLoading ? (
            <h2>Loading...</h2>
          ) : profileError ? (
            <h2>{profileError}</h2>
          ) : (
            <>
              <div class="row">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Edit Profile</h2>
                  </div>
                </div>
              </div>

              <div class="container">
                <Row>
                  <Col lg={4}>
                    <div>
                      <figure class="caption-1">
                        <img
                          src={profileTypeList[profileType].background}
                          alt=""
                          class="w-100 card-img-top rounded effect-2"
                        />

                        <div class="jto-bottom-item">
                          <figcaption class="px-5 py-4 text-right">
                            <h2 class="h5 font-weight-bold mb-0 text-white">
                              <i class={profileTypeList[profileType].icon}></i>
                              &nbsp;
                              {profileTypeList[profileType].type === "business"
                                ? prefix + " " + name
                                : profileTypeList[profileType].name}
                            </h2>
                            <p class="text-small text-white marquee">
                             <span>
                            {profileTypeList[profileType].type === "business"
                                ? company
                                : link}</span>
                            </p>
                          </figcaption>
                        </div>
                      </figure>
                    </div>

                    {/*  <div class="card rounded shadow-sm border-0">
                    <div class="card-body p-0">
                      <div className="item">
                        <img className="w-100"  style={{ objectFit: 'cover',borderRadius: "5px" }} src={"https://justtaponce.s3.ap-south-1.amazonaws.com/templates/Artboard%201.jpg"}/>
                      </div>
                    </div>
                </div>  */}
                  </Col>

                  <Col lg={6}>
                    <Form className="py-2" onSubmit={onSaveProfile}>
                      {formBlock()}

                      <Row>
                        <Col className="col-12 text-center">
                          {progress ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn btn-main-md"
                            >
                              Save
                            </Button>



                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default EditProfilePage;
