import "./card-item.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import { ListGroup,Card } from "react-bootstrap";
import profileTypeList from "../../../utils/profile-type";


const CardItem = ({ card,isEven }) => {

  const [profileType, setProfileType] = useState(-1);

  useEffect(() => {
    //console.log("state - "+state);
    //console.log("state - "+JSON.stringify(state));

    const profileTypeIndex = profileTypeList.findIndex(
      (x) => card.activeProfile != null ? x.type === card.activeProfile.type : false
    );

    if (profileTypeIndex != -1) {
      setProfileType(profileTypeIndex);
    }
  }, []);

  function fullName() {
    if (profileType == -1) {
      return "";
    }

    if (card.activeProfile) {
      var name = card.activeProfile.name;

      if (
        !(card.activeProfile.namePrefix === "undefined") &&
        card.activeProfile.namePrefix
      ) {
        name = card.activeProfile.namePrefix + " " + name;
      }
      return name;
    } else {
      return "";
    }
  }

  // if (card.activeProfile.namePrefix === "undefined") {
  //   card.activeProfile.namePrefix = "";
  // }

  return (
    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
      {/* <Link to={"/cards/" + card.id}> */}
      <Link>
        {profileType == -1 || card.activeProfile == null ? (
          <figure class="caption-1">
            <img
               src={ isEven ? "/api/media/profile-bg-2.png": "/api/media/profile-bg-1.png"/* profileTypeList[0].background */}
              alt=""
              class="w-100 card-img-top rounded effect-2"
            />

            <div class="jto-bottom-item">
              <figcaption class="px-5 py-4 text-left">
                <h2 className={"h5 font-weight-bold mb-0 "+(isEven ? "text-grey":"text-white")}>
                  No Profile Active
                </h2>
                <p class="text-small text-white sort-link marquee">
                  <span></span>
                </p>
              </figcaption>
            </div>
          </figure>
        ) : (
          <figure class="caption-1">
            <img
              src={ isEven ? "/api/media/profile-bg-2.png": "/api/media/profile-bg-1.png"/* profileTypeList[profileType].background */}
              alt=""
              class="w-100 card-img-top rounded effect-2"
            />

            <div class="jto-bottom-item">
              <figcaption className={"px-4 py-4 text-left "+(isEven ? "text-grey":"text-white")}>
                <h2 className={"h5 font-weight-bold mb-0 "+(isEven ? "text-grey":"text-white")}>
                  <i class={profileTypeList[profileType].icon}></i>
                  &nbsp;
                  {profileTypeList[profileType].type === "business"
                    ? fullName()
                    : profileTypeList[profileType].name}
                </h2>
                <p className={"text-small sort-link marquee "+(isEven ? "text-grey":"text-white")}>
                  <span>
                    {profileTypeList[profileType].type === "business"
                      ? card.activeProfile.company
                      : card.activeProfile.link}
                  </span>
                </p>
              </figcaption>
            </div>

            <div class="jto-top-item">
            <h6 className={"h6 font-weight-bold mb-0 "+(isEven ? "text-grey":"text-white")}>{card.taps?card.taps:"0"} Taps {card.activeProfile.profileName?(" - "+card.activeProfile.profileName):""}</h6>
            </div>

          </figure>
        )}

        {/* <div>
                      <figure class="caption-1">
                        <img
                          src={profileTypeList[profileType].background}
                          alt=""
                          class="w-100 card-img-top rounded effect-2"
                        />

                        <div class="jto-bottom-item">
                          <figcaption class="px-5 py-4 text-right">
                            <h2 class="h5 font-weight-bold mb-0 text-white">
                              <i class={profileTypeList[profileType].icon}></i>
                              &nbsp; {profileTypeList[profileType].name}
                            </h2>
                            <p class="text-small text-white">
                              {profileTypeList[profileType].type === "business"
                                ? card.activeProfile.name
                                : card.activeProfile.link}
                            </p>
                          </figcaption>
                        </div>
                      </figure>
                    </div> */}

        {/* <div class="card rounded shadow-sm border-0">
          <div class="card-body p-0">
            <div className="item">
              <img
                className="w-100"
                style={{ objectFit: "cover", borderRadius: "5px" }}
                src={
                  "https://justtaponce.s3.ap-south-1.amazonaws.com/templates/Artboard%201.jpg"
                }
              />
            </div>
          </div>
        </div> */}
      </Link>
    </div>
  );
};

export default CardItem;
