import React, { Component , useEffect } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";


class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {

   googleTranslateElementInit = () => {
    
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: false,
        },
        "google-translate"
      );
    };

   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
       
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = this.googleTranslateElementInit;
       
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = [
         "",
         // "workout-statistic",
         // "workout-plan",
         // "distance-map",
         // "diet-food-menu",
         // "personal-record",
      ],

         orders = ["orders"];


      /*   app = [
           "app-profile",
           "post-details",
           "app-calender",
           "email-compose",
           "email-inbox",
           "email-read",
           "ecom-product-grid",
           "ecom-product-list",
           "ecom-product-list",
           "ecom-product-order",
           "ecom-checkout",
           "ecom-invoice",
           "ecom-customers",
           "post-details",
           "ecom-product-detail",
        ],
        email = ["email-compose", "email-inbox", "email-read"],
        shop = [
           "ecom-product-grid",
           "ecom-product-list",
           "ecom-product-list",
           "ecom-product-order",
           "ecom-checkout",
           "ecom-invoice",
           "ecom-customers",
           "ecom-product-detail",
        ],
        charts = [
           "chart-rechart",
           "chart-flot",
           "chart-chartjs",
           "chart-chartist",
           "chart-sparkline",
           "chart-apexchart",
        ],
        bootstrap = [
           "ui-accordion",
           "ui-badge",
           "ui-alert",
           "ui-button",
           "ui-modal",
           "ui-button-group",
           "ui-list-group",
           "ui-media-object",
           "ui-card",
           "ui-carousel",
           "ui-dropdown",
           "ui-popover",
           "ui-progressbar",
           "ui-tab",
           "ui-typography",
           "ui-pagination",
           "ui-grid",
        ],
        plugins = [
           "uc-select2",
           "uc-nestable",
           "uc-sweetalert",
           "uc-toastr",
           "uc-noui-slider",
           "map-jqvmap",
        "uc-lightgallery",
        ],
        widget = ["widget-basic"],
        forms = [
           "form-element",
           "form-wizard",
           "form-editor-summernote",
           "form-pickers",
           "form-validation-jquery",
        ],
        table = ["table-bootstrap-basic", "table-datatable-basic"],
        pages = [
           "page-registser",
           "page-login",
           "page-lock-screen",
           "page-error-400",
           "page-error-403",
           "page-error-404",
           "page-error-500",
           "page-error-503",
        ],
        error = [
           "page-error-400",
           "page-error-403",
           "page-error-404",
           "page-error-500",
           "page-error-503",
        ]; */
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">


                  <li className={`${path === "" ? "mm-active" : ""}`}>
                     <Link
                        to="/"
                        className="ai-icon"

                     >

                        <i className="flaticon-381-home-2"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>




                  <li className={`${path === "users" ? "mm-active" : ""}`}>
                     <Link
                        to="/users"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Users</span>
                     </Link>
                  </li>

                  <li className={`${path === "vlink-users" ? "mm-active" : ""}`}>
                     <Link
                        to="/vlink-users"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">vLink Users</span>
                     </Link>
                  </li>

                  <li className={`${path === "corporate-members" ? "mm-active" : ""}`}>
                     <Link
                        to="/corporate-members"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Corporate Members</span>
                     </Link>
                  </li>


                  <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
                     <Link
                        to="/orders"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-box"></i>
                        <span className="nav-text">Orders</span>
                     </Link>
                  </li>

                  <li className={`${path === "trials" ? "mm-active" : ""}`}>
                     <Link
                        to="/trials"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-box"></i>
                        <span className="nav-text">Trials</span>
                     </Link>
                  </li>


                  <li className={`${path === "corporates" ? "mm-active" : ""}`}>
                     <Link
                        to="/corporates"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-news"></i>
                        <span className="nav-text">Corporates</span>
                     </Link>
                  </li>

                  <li className={`${path === "coupons" ? "mm-active" : ""}`}>
                     <Link
                        to="/coupons"
                        className="ai-icon"

                     >
                        <i className="flaticon-381-price-tag"></i>
                        <span className="nav-text">Coupons</span>
                     </Link>
                  </li>





               </MM>

               <div className="copyright">
                  <p>
                     <strong>Tap1ce Admin</strong> ©All Rights Reserved
                  </p>
                  <p>By Comket Solution Pvt Ltd.</p>
                  <span id="google-translate"></span>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
