const themeList = [
  {
    name: "simple",
    title: "Simple",
  },
  {
    name: "light",
    title: "Light",
  },
  {
    name: "dark",
    title: "Dark",
  },
  {
    name: "custom",
    title: "Custom",
  },
  {
    name: "sbi",
    title: "SBI Bank",
  },
  {
    name: "goodrich",
    title: "Good Rich",
  },
];

export default themeList