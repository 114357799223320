import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";
import { CSVLink } from "react-csv";


//const Orders = () => {
const CorporateUsers = ({ props, history }) => {

   const dispatch = useDispatch();
   const [users, setUsers] = useState([]);
   const [loading, setLoading] = useState(false);

   const [xlsData, setXlsData] = useState([]);

   const sort = 50;
   let pagination = Array(Math.ceil(users.length / sort))
   .fill()
   .map((_, i) => i + 1);

const [activePage, setActivePage] = useState(0);
const [tableData, setTableData] = useState([]);

const [search, setSearch] = useState("");
const [searchTimeout, setSearchTimeout] = useState(null);

useEffect(() => {
   if (searchTimeout) {
     clearTimeout(searchTimeout);
   }

   setSearchTimeout(
     setTimeout(() => {
       loadUsers();
     }, 1000)
   );

   return () => clearTimeout(searchTimeout);
 }, [search]);
  
  
   useEffect(() => {
		loadUsers();
	}, [dispatch]);


   useEffect(() => {

      if(users){
         setLoading(false);
         setTableData( users.slice(
            activePage * sort,
            (activePage + 1) * sort
         ));
      }
     
	}, [users]);


   const loadUsers = async () => {

      const { data } = await axiosInstance.post(
         "admin/all-corporate-members",
         {
            status:"accepted",
            search: search
         }
       );
 
       if(data.status){
         setUsers(data.invitations);
       }
   }

   
   
   const changeUserStatus = async (userId,status) => {

      const { data } = await axiosInstance.post(
         "admin/user-status",
         {
            user_id:userId,
            status:status
         }
      );

      if(data.status){
         swal('User', data.message, "success");

         var dummy = [...users];
         
         const index = dummy.findIndex(
            (x) => x.id === userId
         );

         dummy[index].status = status;
         console.log("order index - "+index);

         setUsers(dummy);
         onClick(activePage);

      }else{
         swal('User', data.message, "error");
      }

   }


   //const [demo, setdemo] = useState();
   const onClick = (i) => {
      console.log("onClick - "+i);
      setActivePage(i);
      setTableData(users.slice(
         i * sort,
         (i + 1) * sort
      ));
   };

   const memberAddress = (member)=>{

      var address = "";

      if(member.address){
         if(member.address.addressLine1){
         
            address = (member.address.addressLine1??"")+", "+
            (member.address.addressLine2??"")+", "+
            (member.address.city??"")+", "+
            (member.address.state??"")+", "+
            (member.address.country??"")+" - "+
            (member.address.zip??"");
         
         }else{
            address = "Not Available";
         }
      }else{
         address = "Not Available";
      }


      return address;

    }

    function orderStatus(status){

      if(status == "Processing" || status == "Printing" || status == "Paired" || status == "Dispatched"){
         return "text-warning";
      }else if(status == "Delivered"){
         return "text-success";
      }else{
         return "text-danger";
      }

    }

    const changeOrderStatus = async (memberId,status) => {

      const { data } = await axiosInstance.post(
         "admin/corporate-member-status",
         {
            invitation_id:memberId,
            status:status
         }
      );

      if(data.status){
         swal('Corporate Member', data.message, "success");
         var dummy = [...users];
         
         const index = dummy.findIndex(
            (x) => x.id === memberId
         );

         dummy[index].orderStatus = status;
         console.log("order index - "+index);

         setUsers(dummy);
         onClick(activePage);


      }else{
         swal('Corporate Member', data.message, "error");
      }

   }

     
  const initXlsData = async () => {

   var dummy = [];
   users.map((data,i)=>{

   
      dummy.push({
         sr_no:i+1,
         id:data.id,
         name:data.name,
         email:data.email,
         phone:data.phone,
         department:data.department,
         designation:data.designation,
         address:memberAddress(data),
         card_is_printed:data.isPrinted?"Yes":"No",
         status:orderStatus(data.orderStatus),
         created_at:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),

         
      });
   });


   setXlsData(dummy);


}



   
   return (

      <Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
         <div className="card">
            <div className="card-header">
            <div class="mr-auto">
               <h4 className="card-title">Coprorate Members</h4>
               </div>

               <div class="">
              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadUsers();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>
				    

              <CSVLink
  data={xlsData}
  filename={"corporate-members.csv"}
  className="btn btn-primary"
  target="_blank"
  asyncOnClick={true}
  onClick={async (event, done) => {
   console.log("You click the link");
   await initXlsData();
   done(); // 👍🏻 You are stopping the handling of component
 }}

>
  Export
</CSVLink>

            </div>
            </div>
            <div className="card-body">

               {loading || users.length == 0 ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:(
       
       <Table responsive className="w-100">
       <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
             <thead>
                <tr role="row">
                   <th key="0">SN</th>
                   <th key="1">Name</th>
                   <th key="2">Email</th>
                   <th key="3">Corporate</th>
                   <th key="4">Department</th>
                   <th key="5">Designation</th>
                   <th key="6">Address</th>
                   <th key="7">Joined</th>
                   <th key="8">Card Printed</th>
                   <th key="9">Status</th>
                   
                </tr>
             </thead>
             <tbody>
                {tableData.map((d, i) => (
                   <tr key={i}>

                      {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                      <td >{i+1}</td> 
                      <td>{d.name}</td>
                      <td>{d.email}</td>
                      <td><Link to={"/corporate/"+d.userId.id}>{d.userId.name}</Link></td>
                      <td>{d.department}</td>
                      <td>{d.designation}</td>
                      <td>{memberAddress(d)}</td>
                      
                     
                      <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                      <td>{d.isPrinted?"Yes":"No"} </td>
                    
                      <td>
                                 
                                 <div className="d-flex align-items-center">
                                    <i className={"fa fa-circle "+orderStatus(d.orderStatus)+" mr-1"}></i>{" "}
                                    {d.orderStatus}
                                 </div>
                                    
                              </td>
                     
                     
                      <td>

                      <Dropdown>
                                             <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                             >
                                                <svg
                                                   width="24px"
                                                   height="24px"
                                                   viewBox="0 0 24 24"
                                                   version="1.1"
                                                >
                                                   <g
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                   >
                                                      <rect
                                                         x="0"
                                                         y="0"
                                                         width="24"
                                                         height="24"
                                                      ></rect>
                                                      <circle
                                                         fill="#000000"
                                                         cx="5"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="12"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="19"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                   </g>
                                                </svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Processing")}>
                                                Processing
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Printing")}>
                                                Printing
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Paired")}>
                                                Paired
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Dispatched")}>
                                                Dispatched
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Delivered")}>
                                                Delivered
                                                </Dropdown.Item>
                                               {/*  <div className="dropdown-divider"></div>
                                                <Dropdown.Item
                                                   href="#"
                                                   className="text-danger"
                                                >
                                                   Delete
                                                </Dropdown.Item> */}
                                             </Dropdown.Menu>
                                          </Dropdown>


                      </td>

                      
                   </tr>
                ))}
             </tbody>
             <tfoot>
                <tr role="row">

                <th key="0">Sr No.</th>
                   <th key="1">Name</th>
                   <th key="2">Email</th>
                   <th key="3">Corporate</th>
                   <th key="4">Department</th>
                   <th key="5">Designation</th>
                   <th key="6">Address</th>
                   <th key="7">Joined</th>
                   <th key="8">Card Printed</th>
                   <th key="9">Status</th>

                </tr>
             </tfoot>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
             <div className="dataTables_info">
                Showing {activePage * sort + 1} to 
                {users.length <
                (activePage + 1) * sort
                   ? users.length
                   : (activePage + 1) * sort}
                &nbsp;of {users.length} entries
             </div>
             <div className="dataTables_paginate paging_simple_numbers">
                <Pagination
                   className="pagination-primary pagination-circle"
                   size="lg"
                >
                   <li
                      className="page-item page-indicator "
                      onClick={() =>
                         activePage > 1 &&
                         onClick(activePage - 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-left" />
                      </Link>
                   </li>
                   {pagination.map((number, i) => (
                         <Pagination.Item
                         key={"page-"+i}
                         className={
                            activePage === i ? "active" : ""
                         }
                         onClick={() => onClick(i)}
                      >
                         {number}
                      </Pagination.Item>
                   ))}
                   <li
                      className="page-item page-indicator"
                      onClick={() =>
                         activePage + 1 <
                            pagination.length &&
                         onClick(activePage + 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-right" />
                      </Link>
                   </li>
                </Pagination>
             </div>
          </div>
       </div>
    </Table>
       
              )}
       


             
         </div>
      </div>
      </div>

      </Fragment>
   );
};

export default CorporateUsers;
